<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">
                    案件材料
                    <el-tag type="warning" style="margin-left: 5px" v-if="caseDetailData && caseDetailData.suspendedFlag">挂起</el-tag>
                </div>
            </el-col>
        </el-row>
        <div class="card_content">
            <div class="tab_content">
                <div class="btn_group">
                    <div class="group_left">
                        <div></div>
                        <el-button type="primary" v-show="materialTable.length > 1" :loading="downloadLoading" @click="downloadCaseFile">批量下载</el-button>
                    </div>
                    <template v-if="btnMenuData && btnMenuData[301]">
                        <div class="group_right" v-if="!editUpload">
                            <el-button type="primary" v-if="0" @click="editUploadFn(true)">编 辑</el-button>
                            <el-button type="primary" @click="showUpload">点击上传</el-button>
                        </div>
                        <div class="group_right" v-else>
                            <el-button type="primary" @click="submitUpload(true)">保 存</el-button>
                            <el-button @click="editUploadFn(false)">取 消</el-button>
                        </div>
                    </template>
                </div>
                <!-- 材料列表 -->
                <el-table :class="{ noneTableData: materialTable.length < 1 }" :data="materialTable" height="300px" style="width: 100%">
                    <el-table-column prop="fileName" label="文件名称" width="180">
                        <template slot-scope="scope">
                            <div class="preview_file">
                                <span style="color: #409eff; cursor: pointer" v-if="fileTypeList.indexOf(scope.row.fileExtension) != -1 || scope.row.fileExtension == 'pdf' || audioTypeList.indexOf(scope.row.fileExtension) != -1" @click="previewFile(scope.row, scope.$index)">
                                    <overflow-txt :txt="scope.row.fileName"></overflow-txt>
                                </span>
                                <span v-else>
                                    <overflow-txt :txt="scope.row.fileName"></overflow-txt>
                                </span>
                                <el-image :ref="'elImage' + scope.$index" class="preview_image" v-if="scope.row.fileExtension && fileTypeList.indexOf(scope.row.fileExtension) != -1" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"> </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" label="文件类型" width="180">
                        <template slot-scope="scope">
                            <div v-if="scope.row.edit">
                                <el-select v-model="scope.row.typeCode" placeholder="请选择">
                                    <el-option v-for="item in caseFileTypeOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                                </el-select>
                            </div>
                            <div v-else>
                                <span>{{ scope.row.typeName }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createBy" label="上传人"> </el-table-column>
                    <el-table-column prop="createTime" label="上传时间"> </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button :loading="scope.row.loading" @click="handleFile(scope.row, scope.$index)" type="text" size="small">下载</el-button>
                            <el-button @click="handleFile(scope.row, scope.$index, 'del')" type="text" size="small" v-if="btnMenuData && btnMenuData[301]">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination
                    v-if="materialTable.length > 0 && 0"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="materialForm.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 20]"
                    :page-size="materialForm.size"
                    :total="materialForm.total"
                >
                </el-pagination>
            </div>
        </div>
        <!-- 上传弹窗 -->
        <el-dialog class="upload_dialog" title="上传材料" :visible.sync="uploadFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="400px">
            <div class="dialog_content">
                <el-form :model="uploadForm" :rules="uploadRules" ref="uploadRef" label-width="120px">
                    <el-form-item label="文件类型：" prop="typeCode">
                        <el-select v-model="uploadForm.typeCode" filterable placeholder="请选择">
                            <el-option v-for="item in caseFileTypeOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="upload_btn">
                    <el-upload ref="elUploadRef" v-if="!selectFileFlag" action="#" :http-request="(e) => ossUpload(e)" multiple :limit="10" :on-exceed="handleExceed" :on-remove="handleRemove" :file-list="fileList" :show-file-list="false">
                        <el-button size="mini" type="primary">选择文件</el-button>
                        <p class="errorTxt" v-if="filesLengthError">最少选择一个文件</p>
                    </el-upload>
                    <el-button size="mini" type="primary" disabled v-else>选择文件</el-button>
                </div>
                <div class="files_box" v-for="(item, index) in uploadCaseFileForm.files" :key="index">
                    <div style="width: 75%"><overflow-txt :txt="item.fileName"></overflow-txt></div>
                    <div>
                        <span style="color: #409eff; margin-right: 10px">{{ item.uploadProgress }}</span
                        ><i class="del_icon el-icon-close" @click="delUploadFile(item, index)"></i>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="entrustFn(true)">提 交</el-button>
                <el-button @click="entrustFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 材料预览弹窗 -->
        <el-dialog class="preview_dialog" title="材料预览" :visible.sync="previewFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="500px">
            <div class="preview_content">
                <el-row>
                    <el-col :span="4">文件类型：</el-col>
                    <el-col :span="10" :offset="1">{{ previewData.typeName }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">上传人：</el-col>
                    <el-col :span="10" :offset="1">{{ previewData.createBy }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">上传时间：</el-col>
                    <el-col :span="10" :offset="1">{{ previewData.createTime }}</el-col>
                </el-row>
            </div>
            <div class="preview_audio" v-if="previewFlag">
                <audio controls>
                    <source :src="previewData.previewPath" type="audio/mpeg" />
                    <source :src="previewData.previewPath" type="audio/ogg" />
                    您的浏览器不支持该音频格式。
                </audio>
                <p style="display: none">{{ previewData.previewPath }}</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'CaseMateria',
    props: {
        caseDetailData: {
            type: Object,
            default: null
        },
        btnMenuData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            downloadLoading: false,
            filesLengthError: false,
            selectFileFlag: false,
            previewFlag: false,
            previewData: {},
            editUpload: false,
            uploadFlag: false,
            OSSClient: null,
            uploadForm: {
                lawPlatform: ''
            },
            uploadRules: {
                typeCode: [{ required: true, message: '请选择文件类型', trigger: 'blur' }]
            },
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            uploadCaseFileForm: {
                caseId: '',
                files: []
            },
            fileList: [],
            materialTable: [],
            materialForm: {
                page: 1,
                size: 10,
                total: 0
            },
            caseFileTypeOptions: [],
            caseFileTypeObj: {
                1: '沟通记录',
                2: '投诉通知书',
                3: '监管报告',
                99: '其他'
            },
            audioTypeList: ['mp3', 'mp4', 'wav', 'wma', 'aac', 'flac', 'ogg'],
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif']
        };
    },
    mounted() {
        this.initOSSClient();
        this.getMaterialType();
    },
    methods: {
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/aliyunoss/access', { params: {} })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        previewFile(caseItem, itemIndex) {
            this.previewData = caseItem;
            if (this.fileTypeList.indexOf(caseItem.fileExtension) != -1) {
                this.$refs['elImage' + itemIndex].clickHandler();
            } else if (caseItem.fileExtension == 'pdf') {
                this.openFileInNewTab(this.getOssFilePath(caseItem.ossPath));
            } else if (this.audioTypeList.indexOf(caseItem.fileExtension) != -1) {
                this.previewFlag = true;
            } else {
                console.log(caseItem.fileExtension);
            }
        },
        getMaterialType() {
            this.instance.get('/complaintMaterial/getMaterialType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseFileTypeOptions = data.data;
                }
            });
        },
        openFileInNewTab(fileUrl) {
            var a = document.createElement('a');
            a.href = fileUrl;
            a.target = '_blank';
            a.click();
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        checkUploadFileLength() {
            this.selectFileFlag = this.uploadCaseFileForm.files.length >= 10;
        },
        downloadCaseFile() {
            this.downloadLoading = true;
            this.instance({
                method: 'post',
                url: '/complaintInfo/downloadMaterial',
                data: { complaintId: this.caseDetailData.id },
                responseType: 'blob',
                headers: {}
            }).then((res) => {
                this.downloadLoading = false;
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(res.headers.filename)); //指定下载后的文件名，防跳转
                document.body.appendChild(link);
                link.click();
            });
        },
        editUploadFn(editFlag) {
            let tmpData = JSON.parse(JSON.stringify(this.materialTable));
            this.tmpTableData = JSON.parse(JSON.stringify(this.materialTable));
            if (editFlag) {
                tmpData.forEach((item, index) => {
                    item.loading = false;
                    item.edit = true;
                    item.typeCode = item.typeCode;
                });

                this.materialTable = tmpData;
                this.editUpload = true;
            } else {
                this.getCaseDetail();
                this.editCaseFileForm = {
                    caseId: '',
                    deleteFiles: [],
                    files: []
                };
                tmpData.forEach((item, index) => {
                    item.loading = false;
                    item.edit = false;
                });

                this.materialTable = tmpData;
                this.editUpload = false;
            }
        },
        getCaseDetail() {
            this.$emit('getCaseDetail');
        },
        showUpload() {
            this.uploadFlag = true;
            this.uploadCaseFileForm = {
                caseId: '',
                files: []
            };
            this.fileList = [];
        },
        //OSS直传
        ossUpload(e, fileType) {
            const OSS = require('ali-oss');
            let _this = this;
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            let tmpSuffix = file.name.split('.');
            tmpSuffix = tmpSuffix[tmpSuffix.length - 1];

            if (file.name.length > 55) {
                this.$message.error('文件名过长，请修改后重试！');
                return;
            }

            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            let tmpImageUrl = null;

            const reader = new FileReader();
            reader.readAsDataURL(file); // 将文件转换成 Base64 格式
            reader.onloadend = () => {
                tmpImageUrl = reader.result; // 保存 Base64 URL 地址
            };

            storeAs = 'complaint/' + this.caseDetailData.id + '/' + uuidv4() + '.' + tmpSuffix;

            this.uploadCaseFileForm.files.push({
                uploadProgress: 0 + '%',
                tmpPath: storeAs,
                complaintId: _this.caseDetailData.id,
                typeCode: _this.uploadForm.typeCode,
                fileName: file.name,
                ossPath: ''
            });

            // if (fileType && fileType != tmpSuffix) {
            //     this.$message.error('请选择正确文件类型');
            //     return;
            // }

            // if (!fileType && fileTypeList.indexOf(tmpSuffix) == -1) {
            //     this.$message.error('请选择正确文件类型');
            //     return;
            // }

            if (this.OSSClient) {
                // storeAs = 'complaint/' + this.caseDetailData.id + '/' + uuidv4() + '.' + tmpSuffix;

                this.OSSClient.multipartUpload(storeAs, file, {
                    progress: (p, checkpoint) => {
                        // 更新上传进度
                        let uploadProgress = Math.floor(p * 100) + '%';
                        _this.uploadCaseFileForm.files.forEach((item) => {
                            if (item.tmpPath == storeAs) {
                                item.uploadProgress = uploadProgress;
                                _this.$forceUpdate();
                            }
                        });
                    }
                })
                    .then(function (result) {
                        console.log(result);
                        // storeAs - 阿里返回图片路径
                        if (_this.uploadCaseFileForm.files.length < 10) {
                            _this.uploadCaseFileForm.files.forEach((item) => {
                                if (item.tmpPath == result.name) {
                                    item.ossPath = result.name;
                                }
                            });
                            // _this.uploadCaseFileForm.files.push({
                            //     complaintId: _this.caseDetailData.id,
                            //     typeCode: _this.uploadForm.typeCode,
                            //     fileName: file.name,
                            //     ossPath: storeAs
                            // });
                            _this.filesLengthError = false;
                            _this.checkUploadFileLength();
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },
        submitUpload() {},
        handleFile(fileItem, fileIndex, fileStr) {
            if (fileStr == 'del') {
                this.instance.post('/complaintMaterial/delMaterial', { materialId: fileItem.id }).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('删除成功！');
                        let delItem = this.materialTable.splice(fileIndex, 1);
                        this.editCaseFileForm.deleteFiles.push(delItem[0]);
                        this.$forceUpdate();
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                let fileUrl = this.getOssFilePath(fileItem.ossPath);
                this.downloadPdf(fileItem, fileIndex, fileUrl);
            }
        },
        getOssFilePath(filePath) {
            let fileUrl = this.OSSClient.signatureUrl(filePath);
            return fileUrl;
        },
        downloadPdf(fileItem, fileIndex, fileUrl) {
            fileItem.loading = true;

            const link = document.createElement('a');
            fetch(fileUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    fileItem.loading = false;
                    link.href = URL.createObjectURL(blob);
                    // 下载文件的名称及文件类型后缀
                    link.download = fileItem.fileName;
                    document.body.appendChild(link);
                    link.click();
                    //在资源下载完成后 清除 占用的缓存资源
                    window.URL.revokeObjectURL(link.href);
                    document.body.removeChild(link);
                });
        },
        handleCurrentChange() {},
        handleSizeChange() {},
        delUploadFile(fileItem, fileIndex) {
            this.uploadCaseFileForm.files.splice(fileIndex, 1);
            this.fileList = this.uploadCaseFileForm.files;
            this.checkUploadFileLength();
        },
        entrustFn(submitFlag) {
            if (submitFlag) {
                if (this.uploadCaseFileForm.files.length < 1) {
                    this.filesLengthError = true;
                    return;
                }
                this.$refs.uploadRef.validate((valid) => {
                    if (valid) {
                        this.uploadCaseFileForm.files.forEach((item) => {
                            item.typeCode = this.uploadForm.typeCode;
                            item.typeName = this.caseFileTypeObj[this.uploadForm.lawPlatform];
                        });
                        this.addCaseFile();
                    }
                });
            } else {
                this.cancelUpload();
            }
        },
        cancelUpload() {
            this.uploadFlag = false;
            this.uploadForm.lawPlatform = '';
            this.fileList = [];
            this.uploadCaseFileForm.files = [];
            this.checkUploadFileLength();
            if (this.$refs.elUploadRef && this.$refs.elUploadRef[0]) {
                this.$refs.elUploadRef[0].clearFiles();
            } else {
                this.$refs.elUploadRef && this.$refs.elUploadRef.clearFiles();
            }
        },
        addCaseFile() {
            let success = true;
            this.uploadCaseFileForm.files.some((item, index) => {
                if (item.uploadProgress != '100%') {
                    success = false;
                    return true;
                }
                return false;
            });

            if (!success) {
                this.$message.error('还有文件未上传完成，请检查后提交！');
                return;
            }
            this.instance.post('/complaintMaterial/addMaterial', this.uploadCaseFileForm.files).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('材料上传成功！');
                    this.$emit('getCaseDetail');
                    this.cancelUpload();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        initCaseMaterial(materialList) {
            // 文件后缀是否可以预览
            let tmpMaterialList = JSON.parse(JSON.stringify(materialList));
            tmpMaterialList.forEach((fileItem, fileIndex) => {
                fileItem.loading = false;
                let strArr = fileItem.fileName.split('.');
                let fileExtension = strArr[strArr.length - 1];
                fileExtension = fileExtension.toLowerCase();

                if (fileItem.typeCode != 99 || this.fileTypeList.indexOf(fileExtension) >= 0) {
                    fileItem.isPreview = true;
                    fileItem.fileExtension = fileExtension;
                    fileItem.previewPath = this.getOssFilePath(fileItem.ossPath);
                }
            });

            this.materialTable = tmpMaterialList;
        }
    },
    watch: {
        caseDetailData: {
            handler(val) {
                val && this.initCaseMaterial(val.materialList);
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
}

.card_content {
    padding-bottom: 20px;
    .content_row {
        .col_title {
            text-align: right;
        }
        .col_txt {
            line-height: 22px;
            .select_user {
                margin-left: 10px;
                margin-block: 5px;
            }
        }
    }

    .tab_content {
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #fff;
        }
        .noneTableData::before {
            height: 0px !important;
        }
        .content_row {
            margin-bottom: 25px;
            .project_row {
                display: flex;
                align-items: center;
            }
            .dy_row {
                // position: relative;
                .col_title {
                    position: absolute;
                    top: 5px;
                }
                // .col_txt {
                //     position: absolute;
                //     right: 0;
                //     top: 0;
                // }
            }
            .situation_box {
                padding: 20px;
                height: 300px;
                overflow-y: scroll;
                border: 1px solid #dcdcdc;
                .situation_item {
                    margin-bottom: 15px;
                }
                .situation_item:last-child {
                    margin-bottom: 0;
                }
            }
            .txt_box {
                display: inline-block;
            }
            .click_txt {
                margin-left: 20px;
                color: #409eff;
                cursor: pointer;
                border-bottom: 1px solid #409eff;
            }
            .info_title {
                width: 100px;
                text-align: right;
                // border: 1px solid red;
            }
            .info_input {
                width: 80%;
            }
            .align_col {
                display: flex;
                align-items: top;
            }
        }
        .btn_group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        // 编辑
        .edit_content {
            .content_row {
                display: flex;
                align-items: center;
            }
            .exception_row {
                display: inline !important;
            }
        }
        .preview_file {
            position: relative;
            xc span {
                cursor: pointer;
                color: #409eff;
            }
            .preview_image {
                // width: 100%;
                // height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 0;
                /deep/ img {
                    opacity: 0 !important;
                }
            }
        }
    }
}

.preview_dialog {
    .preview_content {
        .el-row {
            margin-bottom: 10px;
        }
    }
    .preview_audio {
        margin-top: 20px;
        border-top: 1px solid #dfdfdf;
        padding-top: 20px;
        audio {
            width: 100%;
            height: 30px;
        }
    }
}

.upload_dialog {
    .dialog_content {
        .upload_btn {
            text-align: center;
        }
        .files_box {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .del_icon {
                cursor: pointer;
                padding: 4px;
            }
        }
    }
}
</style>