<template>
    <div class="wrap schedule">
        <div class="page_title">案件日程提醒</div>
        <!-- 总览 -->
        <el-row class="overview_box">
            <el-col :span="7">
                <div class="statistics_box clearfix">
                    <div class="content_fl fl" @click="tabClick(1)">
                        <p>
                            <span>{{ scheduleStatistics.courtTime }}</span
                            >件
                        </p>
                        <p>待开庭</p>
                        <p>诉讼案件总数</p>
                    </div>
                    <div class="content_fr fr" @click="tabClick(2)">
                        <p>
                            <span>{{ scheduleStatistics.courtTime30 }}</span
                            >件
                        </p>
                        <p>30日内待开庭</p>
                        <p>诉讼案件数</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="7" :offset="1">
                <div class="statistics_box clearfix">
                    <div class="content_fl fl" @click="tabClick(3)">
                        <p>
                            <span class="color2">{{ scheduleStatistics.appealClosingDate }}</span
                            >件
                        </p>
                        <p>上诉截止日期内</p>
                        <p>诉讼案件总数</p>
                    </div>
                    <div class="content_fr fr" @click="tabClick(4)">
                        <p>
                            <span class="color2">{{ scheduleStatistics.appealClosingDate30 }}</span
                            >件
                        </p>
                        <p>30日内上诉截止</p>
                        <p>诉讼案件数</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="7" :offset="1">
                <div class="statistics_box clearfix">
                    <div class="content_fl fl" @click="tabClick(5)">
                        <p>
                            <span class="color3">{{ scheduleStatistics.fulfillmentPaymentDate }}</span
                            >件
                        </p>
                        <p>待履行支付</p>
                        <p>诉讼案件总数</p>
                    </div>
                    <div class="content_fr fr" @click="tabClick(6)">
                        <p>
                            <span class="color3">{{ scheduleStatistics.fulfillmentPaymentDate30 }}</span
                            >件
                        </p>
                        <p>30日内履行支付</p>
                        <p>诉讼案件数</p>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!-- 日历 -->
        <div class="calendar_box">
            <el-row class="legend_row calendar_row">
                <el-col :span="24">
                    <el-row>
                        <el-col :span="24" class="color_col"> <span class="color_span color1"></span>开庭日期<span class="color_span color2" style="margin-left: 20px"></span>上诉截止日期<span class="color_span color3" style="margin-left: 20px"></span>履行支付日期 </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="calendar_row">
                <el-col :span="6" v-for="(currentMonth, currentMonthIndex) in calendarArr" :key="currentMonthIndex">
                    <calendar-moment :currentMonth="currentMonth" :currentMonthTimeCase="scheduleTimeCaseObj" @openNewTab="openNewTab"></calendar-moment>
                </el-col>
                <div class="row_btns">
                    <i class="btn_icon el-icon-arrow-left" v-if="prevFlag" @click="btnClick('prev')"></i>
                    <i class="btn_icon btn_right el-icon-arrow-right" v-if="nextFlag" @click="btnClick('next')"></i>
                </div>
            </el-row>
        </div>
        <!-- 表格 -->
        <div class="main">
            <div class="table_box public_box">
                <el-table :data="schedulePageData" sortable="custom" @sort-change="tableSort" v-loading="loading">
                    <el-table-column prop="caseNo" fixed="left" label="案件号" width="200">
                        <template slot-scope="scope">
                            <el-tooltip class="tooltip_text" v-if="scope.row.caseNo.length > 22" effect="dark" :content="scope.row.caseNo" placement="top-start">
                                <span @click="openNewTab(scope.row.id)">{{ scope.row.caseNo ? scope.row.caseNo.slice(0, 21) + '...' : '' }}</span>
                            </el-tooltip>
                            <div class="tooltip_text" @click="openNewTab(scope.row.id)" v-else>{{ scope.row.caseNo }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="courtTime" label="开庭时间" width="140"></el-table-column>
                    <el-table-column prop="updateTime" label="最近更新时间" sortable width="140"></el-table-column>
                    <el-table-column prop="filingNumber" label="归档编号" width="160">
                        <template slot-scope="scope">
                            <el-tooltip v-if="scope.row.filingNumber && scope.row.filingNumber.length > 19" effect="dark" :content="scope.row.filingNumber" placement="top-start">
                                <span>{{ scope.row.filingNumber ? scope.row.filingNumber.slice(0, 19) + '...' : '' }}</span>
                            </el-tooltip>
                            <span v-else>{{ scope.row.filingNumber ? scope.row.filingNumber : '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="courtCaseNo" label="法院案号"></el-table-column>
                    <el-table-column prop="policyNo" label="保单号" width="220"></el-table-column>
                    <el-table-column prop="nameOfDeliveryMan" label="骑手"></el-table-column>
                    <el-table-column prop="plaintiff" label="原告"></el-table-column>
                    <el-table-column prop="productType" label="保险产品类型" width="220"></el-table-column>
                    <el-table-column prop="insureTypeName" label="险种"></el-table-column>
                    <el-table-column prop="accidentCityDistrict" label="出险省市区" width="220"></el-table-column>
                    <el-table-column prop="lawsuitAmount" label="诉讼金额"></el-table-column>
                    <el-table-column prop="checkStatusDesc" label="案件状态" width="200"></el-table-column>
                    <el-table-column prop="currentCaseStepDesc" label="流程状态" width="200"></el-table-column>
                    <el-table-column prop="currentHandler" label="当前处理人"></el-table-column>
                    <el-table-column prop="createTime" label="录入日期" width="120"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="schedulePageForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="schedulePageForm.size" :total="schedulePageForm.total"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import CalendarMoment from '@/components/calendarMoment.vue';
export default {
    components: {
        CalendarMoment
    },
    data() {
        return {
            prevFlag: false,
            nextFlag: true,
            loading: false,
            curYear: moment().year(), //当前年
            navForm: {
                keyword: '',
                caseStep: '',
                page: 1,
                size: 10,
                total: 0
            },
            calendarNum: 0,
            calendarArr: [],
            scheduleStatistics: {},
            scheduleTimeForm: {
                scheduleKey: ''
            },
            scheduleTimeCaseArr: [],
            scheduleTimeCaseObj: {},
            schedulePageForm: {
                reverseOrder: '',
                orderKey: '',
                scheduleKey: '',
                page: 1,
                size: 10,
                total: 0
            },
            schedulePageData: [],
            scheduleKeyObj: {
                1: 'courtTime',
                2: 'courtTime30',
                3: 'appealClosingDate',
                4: 'appealClosingDate30',
                5: 'fulfillmentPaymentDate',
                6: 'fulfillmentPaymentDate30'
            }
        };
    },
    mounted() {
        // 设置日历
        this.setCalendarArr();

        // 总览数据
        this.getScheduleStatistics();
        // 日历数据
        this.getScheduleTime();
        // 列表数据
        this.getSchedulePageCase();
    },
    methods: {
        getScheduleStatistics() {
            this.instance.post('/lawsuitCase/schedule/statistics', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.scheduleStatistics = data.data;
                }
            });
        },
        getScheduleTime() {
            this.instance.post('/lawsuitCase/schedule/statisticsAgg', this.scheduleTimeForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.scheduleTimeCaseArr = data.data;
                    this.scheduleTimeCaseArr.forEach((item) => {
                        item.tmpMonth = item.time.split('-')[1];
                        if (item.tmpMonth[0] == '0') {
                            item.tmpMonth = item.tmpMonth.slice(1);
                        }
                        item.tmpDay = item.time.split('-')[2];
                        item.tmpYear = item.time.split('-')[0];
                    });

                    const processedData = this.scheduleTimeCaseArr.reduce((result, item) => {
                        const [year, month, day] = item.time.split('-').map(Number);
                        let tmpMonth = JSON.parse(JSON.stringify(month));
                        let monthKey = tmpMonth;
                        let tmpYear = item.tmpYear;
                        let tmpDay = Number(day);
                        if (tmpMonth[0] == '0') {
                            tmpMonth = tmpMonth.slice(1);
                        }

                        if (!result[tmpYear]) {
                            result[tmpYear] = {};
                        }

                        if (!result[tmpYear][monthKey]) {
                            result[tmpYear][monthKey] = {};
                        }

                        if (!result[tmpYear][monthKey][tmpDay]) {
                            result[tmpYear][monthKey][tmpDay] = { kvs: item.kvs };
                        }

                        result[tmpYear][monthKey][tmpDay] = item;

                        return result;
                    }, {});

                    this.scheduleTimeCaseObj = processedData;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getSchedulePageCase() {
            this.instance.post('/lawsuitCase/schedule/pageCase', this.schedulePageForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.schedulePageData = data.data.list;
                    this.schedulePageForm.total = data.data.total;
                }
            });
        },
        tabClick(str) {
            this.schedulePageForm.scheduleKey = this.scheduleKeyObj[str];
            this.scheduleTimeForm.scheduleKey = this.scheduleKeyObj[str];
            this.getScheduleTime();
            this.getSchedulePageCase();
        },
        setCalendarArr(appointNum, addFlag) {
            let currentDate = new Date();
            let currentMonth = currentDate.getMonth() + 1;
            this.calendarNum = appointNum ? appointNum + 3 : currentMonth + 3;

            if (!addFlag) {
                this.calendarArr = [];
            }
            for (let i = 0; i <= 3; i++) {
                if (this.calendarNum - i < 25) {
                    this.calendarArr.push(this.calendarNum - i);
                }
            }

            this.calendarArr.sort((a, b) => {
                return a - b;
            });

            if (this.calendarArr[0] == currentMonth) {
                this.prevFlag = false;
            } else {
                this.prevFlag = true;
            }

            if (this.calendarArr[this.calendarArr.length - 1] == 12) {
                this.nextFlag = false;
            } else {
                this.nextFlag = true;
            }
        },
        openNewTab(caseNo) {
            let a = document.createElement('a');
            a.href = `${location.origin}/litigationManage/caseDetail?caseId=${caseNo}`;
            a.target = '_blank';
            a.click();
        },
        handleCurrentChange(val) {
            this.schedulePageForm.page = val;
            this.getSchedulePageCase();
        },
        handleSizeChange(val) {
            this.schedulePageForm.size = val;
            this.getSchedulePageCase();
        },
        btnClick(typeStr) {
            let currentDate = new Date();
            let currentMonth = currentDate.getMonth() + 1;
            switch (typeStr) {
                case 'prev':
                    if (this.calendarArr[0] != currentMonth) {
                        this.setCalendarArr(this.calendarArr[0] - 4);
                    }

                    break;

                default:
                    if (this.calendarNum + 1 < 25) {
                        this.setCalendarArr(this.calendarNum + 1);
                    }

                    break;
            }
        },
        tableSort(sortMap) {
            if (sortMap.order) {
                this.navForm.reverseOrder = sortMap.order === 'descending';
                this.navForm.orderKey = sortMap.prop == 'updateTime' ? 'update_time' : '';
            } else {
                this.navForm.reverseOrder = '';
                this.navForm.orderKey = '';
            }
            this.searchCasePage(true);
        }
    }
};
</script>
<style lang="less">
.schedule {
    .page_title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: #333;
        padding-top: 20px;
        letter-spacing: 4px;
    }
    .overview_box {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .statistics_box {
            padding: 30px 0px;
            background: #f2f2f2;
            display: flex;
            align-items: end;
            justify-content: space-between;
            .content_fl,
            .content_fr {
                height: 150px;
                padding: 30px;
                background: #fff;
                cursor: pointer;
                p:nth-child(1) {
                    margin-bottom: 20px;
                    text-align: center;
                    span {
                        font-size: 25px;
                        margin-right: 5px;
                        font-weight: 600;
                        color: #409eff;
                    }
                }
                p:nth-child(2),
                p:nth-child(3) {
                    text-align: center;
                    line-height: 20px;
                }
            }
            .content_fr {
                height: 120px;
                padding: 20px 30px;
                p:nth-child(1) {
                    margin-bottom: 10px;
                    text-align: center;
                }
            }
            .color2 {
                color: #e6a23c !important;
            }
            .color3 {
                color: #67c23a !important;
            }
        }
    }
    .calendar_box {
        margin-top: 30px;
        .legend_row {
            margin-bottom: 10px;
            padding-left: 10px;
            .color_col {
                align-items: center;
                .color_span {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }
                .color1 {
                    background: #409eff;
                }
                .color2 {
                    background: #e6a23c;
                }
                .color3 {
                    background: #67c23a;
                }
            }
        }
        .calendar_row {
            width: 97%;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            .row_btns {
                width: 100%;
                position: relative;
                .btn_icon {
                    position: absolute;
                    left: -20px;
                    top: 100px;
                    font-size: 30px;
                    cursor: pointer;
                    padding: 10px 0px;
                    // border: 1px solid red;
                }
                .btn_right {
                    left: auto;
                    right: -20px;
                }
            }
        }
    }
    .table_box {
        margin-top: 30px;
        padding: 20px;
        .tooltip_text {
            cursor: pointer;
            color: #409eff;
        }
    }
}
</style>