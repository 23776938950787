<template>
    <div>
        <div class="point_box">
            <div class="search_input">
                <div class="input_box">
                    <i class="closeBtn el-icon-circle-close" v-if="caseInput.length > 0" @click="clearInput"></i>
                    <el-input v-model="caseInput" type="textarea" clearable autosize placeholder="请输入，多个案件号回车换行"> </el-input>
                </div>
                <el-select class="input_select" v-model="searchCaseData.accidentProvince" filterable clearable placeholder="请选择" @change="enterProvinceChange">
                    <el-option v-for="item in provinceOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName"> </el-option>
                </el-select>
                <el-select class="input_select" v-model="searchCaseData.accidentCity" filterable clearable placeholder="请选择" @change="enterCityChange">
                    <el-option v-for="item in cityOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName"> </el-option>
                </el-select>
                <el-select class="input_select" v-model="searchCaseData.accidentDistrict" filterable clearable placeholder="请选择">
                    <el-option v-for="item in districtOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName"> </el-option>
                </el-select>
                <el-button class="searchBtn" type="primary" @click="searchCase">查 询</el-button>
            </div>
            <div class="result_box">
                <img src="../../../public/images/piont/db.png" /> 出险点位：<span class="errorTxt">{{ resultData.accidentNum }}</span> 个；<img src="../../../public/images/piont/db1.png" /> 修理厂点位：<span class="successTxt">{{ resultData.repairNum }}</span> 个；
            </div>
            <baidu-map id="container" @ready="handler" :center="center" :zoom="zoom" :max-zoom="16" :mapClick="false" :scroll-wheel-zoom="true">
                <template v-for="(pointItem, pointIndex) in points">
                    <bm-marker @click="lookDetail(pointItem, pointIndex)" :position="pointItem.location" :icon="pointItem.isGarage ? pointIcon1 : pointIcon" :key="pointIndex">
                        <!-- 出险地址 -->
                        <template v-if="!pointItem.isGarage">
                            <bm-info-window :position="pointItem.location" :show="markerShowFlag && activeIndex == pointIndex" @close="markerShowFlag = false" @open="markerShowFlag = true">
                                <div style="width: 230px; visibility: hidden; margin-top: -8px">1</div>
                                <p>案件号: {{ pointItem.caseNo }}</p>
                                <el-row>
                                    <el-col class="txtRight" :span="5">修理厂：</el-col>
                                    <el-col :span="19">{{ pointItem.info.repairWorkshop }}</el-col>
                                </el-row>
                            </bm-info-window>
                        </template>
                        <!-- 修理厂 -->
                        <template v-else>
                            <bm-info-window :position="pointItem.location" :show="markerShowFlag && activeIndex == pointIndex" @close="markerShowFlag = false" @open="markerShowFlag = true">
                                <div style="width: 230px; visibility: hidden; margin-top: -8px">1</div>
                                <el-row>
                                    <el-col class="txtRight" :span="4">修理厂：</el-col>
                                    <el-col :span="20">{{ pointItem.info.repairWorkshop }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col class="txtRight" :span="4">地址：</el-col>
                                    <el-col :span="20">{{ pointItem.info.accidentAddress }}</el-col>
                                </el-row>
                                <el-row v-if="pointItem.info.repairWorkshop && repairWorkObj[pointItem.info.repairWorkshop] && repairWorkObj[pointItem.info.repairWorkshop].length > 0">
                                    <el-col class="txtRight" :span="4">其他：</el-col>
                                    <!-- <el-col :span="18">{{ repairWorkObj[pointItem.info.repairWorkshop][0].repairWorkshop }}</el-col> -->
                                    <el-col :span="20"
                                        ><span v-if="pointItem.info.repairWorkshop && repairWorkObj[pointItem.info.repairWorkshop] && repairWorkObj[pointItem.info.repairWorkshop].length > 0">{{ repairWorkObj[pointItem.info.repairWorkshop].length }} 个相关案件；</span
                                        ><span v-if="pointItem.pointResult && pointItem.pointResult.length > 0">{{ pointItem.pointResult.length }} 个疑似修理厂；</span></el-col
                                    >
                                </el-row>
                                <template v-if="pointItem.info.repairWorkshop && repairWorkObj[pointItem.info.repairWorkshop] && repairWorkObj[pointItem.info.repairWorkshop].length > 0">
                                    <p>---------------------------------------------------------</p>
                                    <el-row style="max-height: 200px; overflow-y: auto" v-for="(addressItem, addressIndex) in repairWorkObj[pointItem.info.repairWorkshop]" :key="addressIndex">
                                        <el-col :class="{ txtRight: 1, hideTitle: addressIndex > 0 }" :span="4">
                                            <!-- 其他： -->
                                            <el-row>案件号：</el-row>
                                            <!-- <el-row>修理厂：</el-row>
                                            <el-row>地址：</el-row> -->
                                        </el-col>
                                        <!-- <el-col :span="18">{{ repairWorkObj[pointItem.info.repairWorkshop][0].repairWorkshop }}</el-col> -->
                                        <el-col :span="20">
                                            <el-row>{{ addressItem.caseNo }}</el-row>
                                            <!-- <el-row>{{ addressItem.repairWorkshop }}</el-row>
                                            <el-row>{{ addressItem.accidentAddress }}</el-row> -->
                                        </el-col>
                                    </el-row>
                                </template>
                            </bm-info-window>
                        </template>
                    </bm-marker>
                </template>
            </baidu-map>
        </div>
    </div>
</template>
<script>
import { BmlMarkerClusterer } from 'vue-baidu-map';
export default {
    components: {
        BmlMarkerClusterer
    },
    data() {
        return {
            activeIndex: null,
            markerShowFlag: false,
            textareaFlag: false,
            // caseInput: '812020080703202312041000039_6 812020080703202312071000210 812020080703202312041000039_8 812020080703202312041000039_9',
            // caseInput: '1830833568453070905 1830142134670843956',
            // caseInput: '1830833568453070905',
            // caseInput: '1830830248443723796',
            caseInput: '',
            searchCaseData: {
                caseNos: [],
                accidentProvince: '',
                accidentCity: '',
                accidentDistrict: ''
            },
            BMap: '',
            zoom: 13,
            center: { lng: 116.403694, lat: 39.922904 },
            // center: { lng: 112.999524, lat: 28.094578 },
            pointIcon: {
                url: require('../../../public/images/piont/db.png'),
                size: {
                    width: 30,
                    height: 40
                },
                opts: {
                    anchor: {
                        width: 14,
                        height: 33
                    }
                }
            },
            pointIcon1: {
                url: require('../../../public/images/piont/db1.png'),
                size: {
                    width: 30,
                    height: 40
                },
                opts: {
                    anchor: {
                        width: 10,
                        height: 40
                    }
                }
            },
            points: [
                {
                    caseNo: '1830830248443723796',
                    info: { caseNo: '1830830248443723796', accidentCityDistrict: '湖南省长沙市芙蓉区', accidentPlace: '人民东路和古曲路交叉路口', accidentAddress: '湖南省长沙市芙蓉区人民东路和古曲路交叉路口', repairWorkshop: '湖南海晟通汽车服务有限公司' },
                    location: { lng: 113.04632802102746, lat: 28.20111325213627 }
                },
                {
                    caseNo: '1830830248443723796',
                    isGarage: true,
                    info: { caseNo: '1830830248443723796', accidentCityDistrict: '湖南省长沙市芙蓉区', accidentPlace: '人民东路和古曲路交叉路口', accidentAddress: '湖南省长沙市芙蓉区人民东路和古曲路交叉路口', repairWorkshop: '湖南海晟通汽车服务有限公司' },
                    name: '湖南海晟通汽车服务有限公司',
                    location: { lat: 28.094578, lng: 112.999524 }
                }
            ],
            pointsArr: [],
            resultData: {
                accidentNum: 0,
                repairNum: 0
            },
            tmpMap: null,
            provinceOptions: [],
            provinceObj: {},
            cityOptions: [],
            districtOptions: [],
            repairWorkObj: {
                湖南海晟通汽车服务有限公司: [
                    {
                        caseNo: '1830830248443723796',
                        accidentCityDistrict: '湖南省长沙市芙蓉区',
                        accidentPlace: '人民东路和古曲路交叉路口',
                        accidentAddress: '湖南省长沙市芙蓉区人民东路和古曲路交叉路口',
                        repairWorkshop: '湖南海晟通汽车服务有限公司'
                    }
                ]
            }
        };
    },
    mounted() {
        // 查询省市
        this.findProvince();
    },
    methods: {
        handler({ BMap, map }) {
            this.BMap = BMap;
            this.BDmap = map;
        },
        searchCase() {
            if (this.caseInput.length > 0) {
                let inputStr = this.caseInput.split('\n');
                this.searchCaseData.caseNos = inputStr;
            }
            this.points = [];
            this.pointsArr = [];
            this.resultData = {
                accidentNum: 0,
                repairNum: 0
            };
            this.repairWorkObj = {};
            this.instance.post('/caseSearch/searchCaseList', this.searchCaseData).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    let tmpCaseList = data.data.caseList;
                    if (tmpCaseList && tmpCaseList.length > 0) {
                        this.repairWorkObj = data.data.repairWorkshopMap;
                        // if (this.caseInput == '1830830248443723796') {
                        tmpCaseList.forEach((item, index) => {
                            if (item.accidentAddress) {
                                this.resultData.accidentNum++;
                                this.addressPoint(item);
                            }
                            if (item.repairWorkshop) {
                                this.suggestion(item);
                            }
                        });
                        // }
                    } else {
                        this.$message.error('未查询到有效数据！');
                    }
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        lookDetail(item, index) {
            this.activeIndex = index;
            this.markerShowFlag = true;
        },
        suggestion(item) {
            this.$jsonp(`https://api.map.baidu.com/place/v2/suggestion?query=${item.repairWorkshop}&region=${item.accidentCityDistrict}&city_limit=true&output=json&ak=${this.$$ak}`, {}).then((res) => {
                if (res.result) {
                    let garagePoint;

                    if (res.result.length == 1) {
                        garagePoint = res.result[0].location;
                        this.resultData.repairNum++;
                        this.points.push({
                            caseNo: item.caseNo,
                            isGarage: true,
                            info: item,
                            name: item.repairWorkshop,
                            location: garagePoint,
                            pointResult: res.result
                        });
                    }
                }
            });
        },
        // 获取经纬度
        addressPoint(item) {
            // caseNo, addressStr
            // item.caseNo, item.accidentAddress
            this.$jsonp(`https://api.map.baidu.com/geocoding/v3/?address=${item.accidentAddress}&output=json&ak=${this.$$ak}`, {}).then((res) => {
                if (res.result) {
                    this.points.push({
                        caseNo: item.caseNo,
                        info: item,
                        location: res.result.location
                    });
                    this.pointsArr.push(res.result.location);
                    this.center = res.result.location;
                    this.updateMapView();
                }
            });
        },
        // 更新地图视角
        updateMapView() {
            let view = this.BDmap.getViewport(eval(this.pointsArr));
            this.zoom = view.zoom - 1;
            this.center = view.center;
        },
        findProvince() {
            this.instance.post('/area/findProvince', {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.provinceOptions = data.data;
                    this.provinceObj = {};
                    data.data.forEach((item) => {
                        this.provinceObj[item.areaName] = item.areaCode;
                    });
                }
            });
        },
        findCityChange() {
            this.cityOptions = [];
            let code = this.provinceObj[this.searchCaseData.accidentProvince];
            this.instance.post('/area/findCity/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.cityOptions = data.data;
                    this.cityObj = {};
                    data.data.forEach((item) => {
                        this.cityObj[item.areaName] = item.areaCode;
                    });
                }
            });
        },
        findDistrict() {
            this.districtOptions = [];
            let code = this.cityObj[this.searchCaseData.accidentCity];
            this.instance.post('/area/findDistrict/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.districtOptions = data.data;
                }
            });
        },
        enterProvinceChange() {
            this.searchCaseData.accidentCity = '';
            this.searchCaseData.accidentDistrict = '';
            this.findCityChange();
        },
        enterCityChange() {
            this.searchCaseData.accidentDistrict = '';
            this.findDistrict();
        },
        clearInput() {
            this.caseInput = '';
            this.searchCaseData.caseNos = [];
        }
    }
};
</script>
<style lang="less" scoped>
.point_box {
    width: 100%;
    height: 90vh;
    position: relative;
    .search_input {
        position: absolute;
        top: 20px;
        left: 20px;
        // width: 900px;
        display: flex;
        align-items: start;
        z-index: 2004;
        .input_box {
            width: 200px;
            display: inline-block;
            position: relative;
            :deep(.el-textarea__inner) {
                overflow: hidden; /* 禁用滚动条 */
                resize: none; /* 禁止用户手动调整文本框的尺寸 */
            }
            .el-textarea {
                /deep/ textarea {
                    padding: 5px 10px;
                }
            }
            .closeBtn {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 2004;
                cursor: pointer;
                font-size: 14px;
                color: #c0c4cc;
            }
        }
        .input_select {
            width: 100px;
            margin-left: 10px;
        }
        .searchBtn {
            margin-left: 10px;
        }
    }
    .result_box {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 300px;
        display: flex;
        // justify-items: center;
        z-index: 2004;
        background: #fff;
        padding: 6px 8px;
        border-radius: 4px;
        border: 1px solid #d3d3d3;
        img {
            width: 13px;
            height: 13px;
            margin-top: 1px;
            margin-right: 2px;
        }
        .errorTxt {
            color: red;
            margin-right: 5px;
        }
        .successTxt {
            color: #409eff;
            margin-right: 5px;
        }
    }
    .txtRight {
        text-align: right;
    }
    .hideTitle {
        visibility: hidden;
    }
}
#container {
    width: 100%;
    height: 100%;
}
</style>