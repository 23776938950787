<template>
    <div class="calendarBox">
        <p class="calendar_title">{{ showYear }} 年 {{ showMonth }} 月</p>
        <div class="weeks">
            <div v-for="item in week" :key="item" class="week">{{ item }}</div>
        </div>
        <div class="days">
            <!-- 上个月 -->
            <div class="lastday" v-for="item in getFirstDayOfWeekOfMonth(showYear, showMonth) - 1" :key="item + 'pre'">
                <span style="visibility: hidden">{{ item }}</span>
            </div>
            <!-- 当月 -->
            <div v-for="item in curDays" :key="item + 'cur'" class="curday">
                <template v-if="currentMonthTimeCase && currentMonthTimeCase[showYear] && currentMonthTimeCase[showYear][showMonth] && currentMonthTimeCase[showYear][showMonth][item]">
                    <el-tooltip class="tooltip_text" effect="dark" placement="top-start">
                        <template v-for="(kvsItem, kvsIndex) in currentMonthTimeCase[showYear][showMonth][item].kvs">
                            <div class="click_txt" style="cursor: pointer; border-bottom: 1px solid #fff; margin-bottom: 5px" slot="content" :key="kvsIndex" @click="tooltipClick(kvsItem.id)">{{ kvsItem.name }}</div>
                            <div :class="{ tooltip_date: 1 }" :color-arr="currentMonthTimeCase[showYear][showMonth][item].colorArr" :key="kvsIndex">
                                <div class="date">{{ item }}</div>
                            </div>
                        </template>
                    </el-tooltip>
                </template>
                <div v-else>
                    <div class="date">{{ item }}</div>
                </div>
            </div>
            <!-- 下个月 -->
            <!-- <div v-for="item in 6 - nextDays" :key="item + 'next'" class="lastday">
                {{ item }}
            </div> -->
        </div>
    </div>
</template>

<script>
import moment from 'moment';
moment.suppressDeprecationWarnings = true;
export default {
    props: {
        currentMonth: {
            type: Number,
            default: 0
        },
        currentMonthTimeCase: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            // 日历 start
            preDays: 30,
            nextDays: 7,
            curYear: moment().year(), //当前年
            curMonth: moment().month() + 1, //当前月
            week: ['一', '二', '三', '四', '五', '六', '七'],
            firstDay: moment(`${moment().year()}-${moment().month() + 1}`)
                .startOf('month')
                .day(), //获取当月第一天是星期几;星期日为 0，星期六为 6
            // curDays: moment().daysInMonth() //获取当月一共有多少天
            curDays: moment().daysInMonth(), //获取当月一共有多少天
            // 日历 end
            colorData: {
                appealClosingDate: '#e6a23c',
                courtTime: '#409eff',
                fulfillmentPaymentDate: '#67c23a'
            },
            showYear: 0,
            showMonth: 0
        };
    },
    computed: {
        tooltipContent() {
            // 使用 join 方法将数组转换为字符串，并使用 \n 作为分隔符
            return this.dataArray.join('\n');
        }
    },
    mounted() {
        this.getPreMonthDays();
        this.getNextMonthDays();
    },
    methods: {
        // 获取月份1日是星期几
        getFirstDayOfWeekOfMonth(year, month) {
            // JavaScript的Date对象月份是从0开始的，所以我们需要对输入的月份进行减一处理
            // 但是如果month是1（代表2月），在减一之后需要判断是否是闰年，并相应地调整日期
            month = month - 1;

            // 创建一个Date对象表示该月的第一天
            var date = new Date(year, month, 1);

            // 获取星期几的索引（0代表星期日，1代表星期一，依此类推）
            var dayOfWeekIndex = date.getDay();

            // 创建一个包含星期几名称的数组
            var days = ['7', '1', '2', '3', '4', '5', '6'];

            // 返回该月1日是星期几的字符串
            return days[dayOfWeekIndex];
        },
        // 日历 start
        preMonth() {
            this.curMonth--;
            // 如果小于1表示上一年;重置日期
            if (this.curMonth < 1) {
                this.curYear--;
                this.curMonth = 12;
            }
            this.curDays = moment(`${this.curYear}-${this.curMonth}`).daysInMonth();
            this.firstDay = moment(`${this.curYear}-${this.curMonth}`).startOf('month').day();
            if (this.firstDay == 0) {
                this.firstDay = 7;
            }
            // 显示上个月日期
            this.getPreMonthDays();
            this.getNextMonthDays();
        },
        nextMonth() {
            this.curMonth++;
            // 如果超过12表示下一年;重置日期
            if (this.curMonth > 12) {
                this.curYear++;
                this.curMonth = 1;
            }
            this.curDays = moment(`${this.curYear}-${this.curMonth}`).daysInMonth();
            this.firstDay = moment(`${this.curYear}-${this.curMonth}`).startOf('month').day();
            if (this.firstDay == 0) {
                this.firstDay = 7;
            }
            // 显示上个月日期
            this.getPreMonthDays();
            this.getNextMonthDays();
        },
        // 获取上个月剩余天数
        getPreMonthDays() {
            if (this.firstDay == 1) return; //表示上个月无剩余天数
            let month = this.curMonth;
            let year = this.curYear;
            month--;
            if (month == 0) {
                year--;
                month = 12;
            }
            // 获取上个月的天数
            const days = moment(`${year}-${month}`).daysInMonth();
            this.preDays = days;
        },
        // 获取下个月要显示的天数
        getNextMonthDays() {
            let month = this.curMonth;
            let year = this.curYear;
            // 获取当月最后一天是星期几
            const lastDay = moment(`${year}-${month}`).endOf('month').day();
            this.nextDays = lastDay == 0 ? 7 : lastDay;
        },
        getDaysInMay(num) {
            // 使用moment.js的daysInMonth方法获取5月的天数
            return moment()
                .month(num - 1)
                .daysInMonth(); // 注意：month方法的参数是从0开始的，所以5月是4
        },
        // 日历 end
        tooltipClick(caseId) {
            this.$emit('openNewTab', caseId);
        },
        setMarkColor() {
            let dateEl = document.querySelectorAll('.tooltip_date');
            let elColorArr;
            dateEl.forEach((ele) => {
                elColorArr = ele.getAttribute('color-arr') ? ele.getAttribute('color-arr').split(',') : [];
                switch (elColorArr.length) {
                    case 3:
                        ele.style.background = `conic-gradient(${elColorArr[0]} 0% 33.33%, ${elColorArr[1]} 33.33% 66.66%, ${elColorArr[2]} 66.66% 100%)`;

                        break;
                    case 2:
                        ele.style.background = `conic-gradient(${elColorArr[0]} 0% 50%, ${elColorArr[1]} 50% 100%)`;

                        break;

                    default:
                        ele.style.background = `conic-gradient(${elColorArr[0]} 0% 50%)`;
                        break;
                }
            });
        },
        setElCaseColor(currentMonthCaseData) {
            if (currentMonthCaseData) {
                for (const key in currentMonthCaseData) {
                    let tmpYearKey = currentMonthCaseData[key];
                    for (const key in tmpYearKey) {
                        let tmpMonthKey = tmpYearKey[key];
                        for (const key in tmpMonthKey) {
                            let tmpDayKey = tmpMonthKey[key];

                            tmpDayKey.colorNum = tmpDayKey.appealClosingDate + tmpDayKey.courtTime + tmpDayKey.fulfillmentPaymentDate;

                            // 每个日期结算color颜色
                            let color1, color2, color3;

                            tmpDayKey.colorArr = [];
                            if (tmpDayKey.appealClosingDate > 0) {
                                color1 = this.colorData['appealClosingDate'];
                                tmpDayKey.colorArr.push(color1);
                            }
                            if (tmpDayKey.courtTime > 0) {
                                color2 = this.colorData['courtTime'];
                                tmpDayKey.colorArr.push(color2);
                            }
                            if (tmpDayKey.fulfillmentPaymentDate > 0) {
                                color3 = this.colorData['fulfillmentPaymentDate'];
                                tmpDayKey.colorArr.push(color3);
                            }
                        }
                    }
                }
            }
        }
    },
    updated() {
        // 日期设置对应色块
        this.setMarkColor();
    },
    watch: {
        currentMonth: {
            handler(val) {
                if (val < 1) {
                    this.currentMonth = this.curMonth;
                } else {
                    this.curDays = this.getDaysInMay(this.currentMonth);
                }

                if (val) {
                    this.showMonth = val > 12 ? val - 12 : val;
                    this.showYear = val > 12 ? this.curYear + 1 : this.curYear;
                }
            },
            immediate: true
        },
        currentMonthTimeCase: {
            handler(val) {
                val && this.setElCaseColor(val);
            },
            immediate: true
        }
    }
};
</script>
<style lang="less">
.calendarBox {
    width: 95%;
    height: 255px;
    padding: 10px;
    background: #fff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .calendar_title {
        text-align: center;
        font-size: 16px;
        margin-bottom: 5px;
    }
    .weeks {
        width: 100%;
        height: 30px;
        display: flex;
        .week {
            width: 100px;
            line-height: 30px;
            text-align: center;
            background: gainsboro;
            background: #e5e5e5;
        }
    }
    .days {
        width: 100%;
        height: 30px;
        display: flex;
        flex-wrap: wrap;
        .lastday,
        .curday {
            width: 14.25%;
            line-height: 30px;
            text-align: center;
            display: flex;
            justify-content: center;
            .date {
                width: 30px;
                height: 30px;
            }
        }
        .curday:nth-child(7n) {
            margin-right: 0;
        }
        .lastday {
            color: gold;
        }
        .tooltip_date {
            // background: red;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
        }
        .click_txt {
            cursor: pointer;
        }
    }
}

// .circle3 {
//     border-radius: 50%;
//     background: conic-gradient(#67c23a 0% 33.33%, #e6a23c 33.33% 66.66%, #409eff 66.66% 100%);
// }
// .circle2 {
//     border-radius: 50%;
//     background: conic-gradient(red 0% 50%, blue 50% 100%);
// }
// .circle1 {
//     border-radius: 50%;
//     background: blue;
// }
</style>