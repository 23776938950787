<template>
    <div class="header">
        <div class="flex header_left">
            <div @click="linkHomePage" class="decoration_box">
                <div class="logo">
                    <img src="../../public/images/logo.png" />
                </div>
            </div>
            <div class="fold_icon">
                <i v-show="!isFold" class="el-icon-s-fold" @click="handleFoldMenus(true)"></i>
                <i v-show="isFold" class="el-icon-s-unfold" @click="handleFoldMenus(false)"></i>
            </div>
        </div>
        <div class="header_right">
            <div class="header_message">
                <div ref="messageHot" :class="messageArr.length > 0 ? 'message_right message_hot' : 'message_right'">
                    <!-- 消息提醒弹窗 -->
                    <div class="message_popup">
                        <div class="popup_title clearfix" @click="toggleDiv">
                            <img class="msg_picture" src="../../public/images/msg.png" />
                        </div>
                        <div class="popup_clear" v-show="!isCollapse && messageArr.length > 0 && 0"><span @click="clearMsg">一键已读</span></div>
                        <ul v-show="!isCollapse" :class="isCollapse ? 'popup_lists retract_lists clearfix' : messageArr.length == 1 ? 'popup_lists clearfix list-over list_height' : 'popup_lists clearfix list-over'" ref="box1">
                            <template v-for="(item, index) in messageArr">
                                <li class="fl" :key="index">
                                    <el-row class="lists-row">
                                        <el-col class="lists-col col-title" :span="24">
                                            <i class="title-error el-icon-warning-outline"></i>
                                            <span>【{{ item.noticeTypeDesc }}】</span>
                                        </el-col>
                                    </el-row>
                                    <el-row class="lists-row">
                                        <el-col class="lists-col col_title" :span="7">投诉编号：</el-col>
                                        <el-col class="lists-col col_txt" :span="16">
                                            <div class="tooltip_text" @click="copyCaseNo(item.caseNo)">
                                                <overflow-txt :txt="item.complaintNumber"></overflow-txt>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row class="lists-row">
                                        <el-col class="lists-col col_title" :span="7">登记日期：</el-col>
                                        <el-col class="lists-col col_txt" :span="14">{{ item.recordDate ? item.recordDate : '--' }}</el-col>
                                    </el-row>
                                    <el-row class="lists-row">
                                        <el-button type="primary" size="mini" @click="otherClick(item)">查 看</el-button>
                                    </el-row>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="userinfo">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link"> {{ nickname }}<i class="el-icon-caret-bottom el-icon--right"></i> </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="clearfix" @click.native="handleLogOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-dialog title="修改密码" width="600px" :visible.sync="centerDialogVisible" center>
            <el-form @keyup.enter.native="handleLogin" ref="form" :model="form">
                <el-form-item label="原始密码">
                    <el-input v-model="form.oldPassword" placeholder="请输入原始密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input v-model="form.newPassword" placeholder="请输入新密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="重复新密码">
                    <el-input v-model="form.newPassword2" placeholder="请重复输入新密码" type="password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleEditPassword">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import overflowTxt from './overflowTxt.vue';
export default {
    components: { overflowTxt },
    name: 'MyHeader',
    props: {
        msg: String,
        checkedMenu: Object
    },
    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                newPassword2: ''
            },
            isFold: true,
            centerDialogVisible: false,
            username: '',
            nickname: '',
            menus: [],
            messageArr: [],
            isCollapse: true,
            noticeListTime: null,
            showMsgData: [],
            showMsg: []
        };
    },
    methods: {
        otherClick(row, rowIndex) {
            // 先关闭该弹框
            this.showNotice(row.id, row.complaintId);
            // 收起消息列表
            this.toggleDiv();
        },
        clearMsg() {
            this.toggleDiv();
            this.messageArr.forEach((msgItem) => {
                this.closeNotice(msgItem.id);
            });

            setTimeout(() => {
                this.messageArr = [];
            }, 500);
        },
        toggleDiv() {
            this.isCollapse = !this.isCollapse;
            if (this.isCollapse && this.$refs['box1']) {
                this.$refs['box1'].style.height = 0;
                this.$refs['messageHot'].style.zIndex = 0;
            } else if (this.$refs['box1']) {
                this.$refs['box1'].style.height = '490px';
                this.$refs['messageHot'].style.zIndex = 12;
            }
        },
        copyCaseNo(caseNo) {
            let textarea = document.createElement('textarea'); //创建临时元素
            textarea.value = caseNo; //设置要复制的内容
            document.body.appendChild(textarea); //将该元素添加到页面上
            textarea.select(); //选中输入框中的内容
            try {
                let successful = document.execCommand('copy'); //执行复制命令
                if (successful) {
                    this.$message.success('报案号复制成功！');
                } else {
                    console.log('无法复制！');
                }
            } catch (err) {
                console.error('无法复制！', err);
            } finally {
                document.body.removeChild(textarea); //移除临时元素
            }
        },
        //操作修改密码
        handleEditPassword() {
            if (this.form.newPassword != this.form.newPassword2) {
                this.$message.error('抱歉，您两次输入的新密码不一致');
                return false;
            }
            var json = {
                oldPassword: this.form.oldPassword,
                newPassword: this.form.newPassword,
                username: this.username
            };
            this.instance
                .get('/login/changePassword', {
                    params: json
                })
                .then((res) => {
                    var data = res.data;
                    if (data.code == 1) {
                        this.centerDialogVisible = false;
                        this.$confirm('密码修改成功，请重新登录。', '提示', {
                            confirmButtonText: '确定',
                            showCancelButton: false,
                            // cancelButtonText: '取消',
                            type: 'warning'
                        })
                            .then(() => {
                                this.$router.replace({
                                    path: '/'
                                });
                            })
                            .catch(() => {
                                this.$router.replace({
                                    path: '/'
                                });
                            });
                    } else {
                        this.$message.error(data.message);
                    }
                });
        },
        //操作退出
        handleLogOut() {
            this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    localStorage.clear();
                    this.$router.replace({
                        path: '/'
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        //点击logo时的跳转
        linkHomePage() {
            let role = JSON.parse(localStorage.getItem('userInfo')).role;
            if (role == 4) {
                this.$router.push({
                    path: '/bigScreen/index'
                });
            }
        },
        //点击面包屑，是否展开菜单
        handleFoldMenus(isFold) {
            this.isFold = isFold;
            this.$emit('handleFoldMenus', isFold);
        },
        getNoticeList() {
            clearTimeout(this.noticeListTime);
            this.instance.post('/complaintNotice/noticeList', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.messageArr = data.data;
                    this.handleNotice();

                    this.noticeListTime = setTimeout(() => {
                        this.getNoticeList();
                    }, 20000);
                }
            });
        },
        handleNotice() {
            let tmpNotice = JSON.parse(JSON.stringify(this.messageArr));
            let categorizedData = {};
            let handleData = [];
            let showMsgData = [];
            let _this = this;
            let mergeObj = { 1: 4, 2: 4, 3: 5 };

            if (tmpNotice.length < 1) {
                return;
            }

            const aNamesSet = new Set(_this.showMsg.map((item) => item.id));

            const result = tmpNotice.filter((item) => !aNamesSet.has(item.id));

            // console.log('初步筛选');
            // console.log(result);

            result.forEach(function (item) {
                let type = item.noticeType == 1 || item.noticeType == 2 ? 1 : item.noticeType;
                if (item.popWindow) {
                    // console.log('二次筛选:' + item.id);
                    // console.log(item);
                    if (!categorizedData[type]) {
                        categorizedData[type] = {
                            data: [item],
                            count: 1
                        };
                        _this.showMsg.push(item);
                    } else {
                        categorizedData[type].data.push(item);
                        categorizedData[type].count++;
                        _this.showMsg.push(item);
                    }
                }
            });
            for (let type in categorizedData) {
                // console.log('noticeType: ' + type + ', 数量: ' + categorizedData[type].count);
                if (categorizedData[type].count > 3) {
                    showMsgData.push({ msgType: mergeObj[type], msgData: { count: categorizedData[type].count, data: categorizedData[type].data } });
                } else {
                    let typeMergeItems = categorizedData[type].data;

                    typeMergeItems.forEach((item) => {
                        showMsgData.push({ msgType: type, msgData: item });
                    });
                }
            }

            if (showMsgData.length > 0) {
                showMsgData.forEach((msgItem, msgIndex) => {
                    msgItem.offset = msgIndex * 90;
                    this.openNotice(msgItem.msgType, msgItem.msgData, msgItem.offset);
                });
            }

            this.showMsgData = showMsgData;
        },
        openNotice(msgType, msgData, offset) {
            let msgStr = '';

            if (msgType == 4) {
                msgStr = `您有${msgData.count}个案件已经超过时效，请点击查看并处理`;
            } else if (msgType == 5) {
                msgStr = `您有${msgData.count}个案件已经结案，请点击查看`;
            } else {
                msgStr = msgData.noticeMessage;
            }
            var tmpClass = msgData.count ? 'noticeClass' + msgData.data[0].id : 'noticeClass' + msgData.id;
            tmpClass = this.$notify({
                type: 'warning',
                position: 'bottom-right',
                offset: offset,
                customClass: 'noticeMsg',
                message: `${msgStr}`,
                duration: 30000,
                dangerouslyUseHTMLString: true,
                onClose: () => {
                    if (msgData.id) {
                        this.closeNotice(msgData.id);
                        this.delNoticeMsgData([msgData]);
                    } else {
                        msgData.data &&
                            msgData.data.forEach((item) => {
                                this.closeNotice(item.id);
                            });
                        this.delNoticeMsgData(msgData.data);
                    }
                },
                onClick: () => {
                    if (msgData.id && !msgData.count) {
                        this.showNotice(msgData.id, msgData.complaintId);
                        this.delNoticeMsgData([msgData]);
                    } else if (msgData.count && (msgType == 4 || msgType == 1)) {
                        this.delNoticeMsgData(msgData.data);
                        this.$router.push({
                            path: '/complaintManage/myWorkbench',
                            query: {
                                noticeData: JSON.stringify({ code: [401, 301], time: Date.now() })
                            }
                        });
                    } else if (msgData.count && msgType == 5) {
                        this.delNoticeMsgData(msgData.data);
                        this.$router.push({
                            path: '/complaintManage/caseList',
                            query: {
                                noticeData: JSON.stringify({ code: [999], time: Date.now() })
                            }
                        });
                    }
                    tmpClass.close();
                }
            });
        },
        delNoticeMsgData(msgData) {
            if (msgData.length == 0) {
                return;
            }

            let tmpMsgData = JSON.parse(JSON.stringify(this.showMsg));

            const result = tmpMsgData.filter((itemA) => {
                return !msgData.some((itemB) => itemB.id === itemA.id);
            });

            this.showMsg = [];
            this.showMsg = result;
        },
        closeNotice(id, rowIndex, delFlag) {
            this.instance.post('/complaintNotice/noticeClose', { noticeId: id }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (delFlag) {
                        // 删除数组
                        this.messageArr.splice(rowIndex, 1);
                    }
                }
            });
        },
        showNotice(id, complaintId) {
            this.instance.post('/complaintNotice/noticeView', { noticeId: id }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    complaintId ? this.openNewTab(complaintId) : this.openNewTab(id);
                }
            });
        },
        openNewTab(caseId, linkData) {
            let a = document.createElement('a');
            a.href = `${location.origin}/complaintManage/caseDetail?complaintId=${caseId}`;
            a.target = '_blank';
            if (linkData) {
                a.href += linkData;
            }
            a.click();
        }
    },
    mounted() {
        this.username = localStorage.getItem('username') || '匿名用户';
        this.nickname = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).nickName : '';
        this.getNoticeList();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/.el-dialog--center .el-dialog__body {
    padding: 25px 80px 30px;
}
/deep/ .el-dropdown {
    font-size: 16px;
}
.header {
    width: 100%;
    height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262d3c;
    color: #ffffff;

    .userinfo {
        display: flex;
        align-items: center;
        .el-dropdown-link {
            font-family: cursive;
        }
    }
}

.logo {
    width: 90px;
}
.el-icon-s-unfold,
.el-icon-s-fold {
    font-size: 20px;
    cursor: pointer;
}
.decoration_box {
    display: flex;
    align-items: center;
    /* width:4.2rem ; */
}
.decoration_title {
    margin-left: 0.2rem;
    font-size: 0.28rem;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #8bd2eb;
    background: linear-gradient(0deg, #80cee3 0%, #e3f9ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.decoration_title .title_left {
    font-size: 0.16rem;
    width: 0.8rem;
    text-align: center;
}
.decoration_title p {
    line-height: 1.2;
}
.header_left {
    width: 220px;
    justify-content: space-between;
    padding-left: 30px;
    display: flex;
}
.header_right {
    min-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    .el-dropdown {
        color: #fff;
        margin-left: 0.3rem;
        cursor: pointer;
    }

    .header_message {
        display: flex;
        align-items: center;
        padding-top: 4px;
        margin-right: 15px;

        /* 消息浮窗 */
        .message_popup {
            position: absolute;
            z-index: 999;
            right: 0px;
            top: 0px;
            border-radius: 4px;
            .popup_title {
                cursor: pointer;
                position: absolute;
                top: -20px;
                right: 5px;

                width: 30px;
                height: 30px;
                line-height: 48px;
                border-radius: 50%;
                z-index: 50;
                // background: rgb(119 119 119 / 50%);
                text-align: center;
                color: #fff;
                cursor: pointer;
            }
            .popup_clear {
                background: #ebebeb;
                padding: 4px;
                width: 310px;
                margin-bottom: -21px;
                color: #333;
                cursor: pointer;
                position: relative;
                z-index: 9;
                span {
                    cursor: pointer;
                    margin-left: 5px;
                }
            }

            .popup_lists {
                width: 310px;
                height: 490px;
                overflow: hidden;
                transition: height 0.56s;
                margin: 21px 12px 0px 0px;

                li {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #ececec;
                    // padding: 10px 10px;
                    margin-bottom: 10px;
                    background: #fff;
                    .lists-row {
                        margin-bottom: 10px;
                        color: #333;
                        .col-title {
                            background: #ffd0d0;
                            padding: 8px;
                            color: #f00;
                            font-family: 'PingFang SC';
                            font-size: 14px;
                            font-weight: bold;
                            position: relative;
                            .title-error {
                                color: red;
                                font-size: 18px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            span {
                                margin-left: 20px;
                            }
                        }
                        .lists-col {
                            font-family: 'PingFang SC';
                            font-size: 14px;
                            word-wrap: break-word;
                            .btn_show,
                            .btn_handle {
                                width: 70px;
                                height: 26px;
                                line-height: 26px;
                                text-align: center;
                                color: #fff;
                                font-family: 'PingFang SC';
                                font-size: 14px;
                                background: #409efe;
                            }
                            .btn_handle {
                                background: #f00;
                            }
                            .btn_show {
                                margin-left: 15px;
                            }
                        }
                        .col_title {
                            text-align: right;
                        }
                        .col_txt {
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            // min-height: 20px;
                        }
                        .col_caseno {
                            border-bottom: 1px solid #333;
                        }
                        .col-ico {
                            position: absolute;
                            right: 5px;
                            top: 6px;
                            font-size: 16px;
                            cursor: pointer;
                            padding: 4px;
                            color: #333;
                        }
                    }
                    .lists-row:last-child {
                        margin-bottom: 0px !important;
                        border-top: 1px solid #e9e9e9;
                        padding: 10px 0;
                        display: flex;
                        justify-content: center;
                        .lists-col:last-child {
                            margin-left: 30px;
                        }
                    }
                }
            }

            .list-over {
                overflow-y: auto;
                /* 隐藏整个滚动条 */
            }
            ::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
        }
        .msg_txt {
            display: inline-block;
            margin-right: 45px;
            color: #303030;
            font-family: 'PingFang SC';
            font-size: 16px;
        }
        .msg_picture {
            width: 25px;
            height: 25px;
            display: inline-block;
        }
        .message_right,
        .message_hot {
            position: relative;
            cursor: pointer;
            z-index: 1;
        }
        // .message_hot {
        //     z-index: 20;
        // }
        .message_hot::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: red;
            position: absolute;
            right: -1px;
            top: -20px;
            z-index: 1000;
        }
    }
}
.logo {
    width: 120px;
}
.decoration {
    width: 0.8rem;
    margin-right: 0.24rem;
}
.menus {
    font-size: 0.2rem;
}
.menus li {
    padding: 0 0.3rem;
}
.menus li,
.decoration_box,
.contact {
    cursor: pointer;
}
.menus li:hover {
    color: #30fbfe;
    font-weight: 500;
}
.menus .active {
    color: #30fbfe;
    font-weight: 500;
}
</style>
<style lang="less">
.noticeMsg {
    .el-notification__icon {
        margin-top: 5px;
    }
    .el-notification__group {
        .el-notification__content {
            text-align: left !important;
            font-weight: 600;
        }
    }
}
</style>