<template>
    <div class="wrap">
        <div class="task_page" v-show="!caseInfo">
            <div class="page_title"><b>案件筛查</b></div>
            <!--检索部分  -->
            <div class="search_box public_box">
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="案件号：">
                        <el-input class="width_150" v-model="navForm.caseNo" placeholder="请输入案件号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="美团案件号：">
                        <el-input class="width_150" v-model="navForm.meituanCaseNo" placeholder="请输入案件号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="骑手姓名：">
                        <el-input class="width_150" v-model="navForm.deliveryName" maxlength="5" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="骑手身份证：">
                        <el-input class="width_200" v-model="navForm.deliveryIdCard" maxlength="18" placeholder="请输入身份证号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="金额区间：">
                        <el-row style="width: 260px">
                            <el-col :span="9">
                                <el-input class="width_100" v-model.number="navForm.geAmount" maxlength="20" placeholder="请输入" clearable></el-input>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">-</el-col>
                            <el-col :span="9">
                                <el-input class="width_100" v-model.number="navForm.leAmount" maxlength="20" placeholder="请输入" clearable></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="质检状态：">
                        <el-select class="width_140" v-model="navForm.qualityStatus" placeholder="请选择" clearable @change="qualityChange">
                            <el-option v-for="item in qualityInspectionOption" :key="item.metaData" :label="item.dictName" :value="item.metaData"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="车损风险标签：">
                        <el-select class="width_140" v-model="navForm.category3" placeholder="请选择" multiple clearable>
                            <template v-for="item in category3Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="人伤风险标签：">
                        <el-select class="width_140" v-model="navForm.injuryCategory2" placeholder="请选择" multiple clearable>
                            <template v-for="item in riskTypeOption2">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="流入时间：" class="dateBox">
                        <el-row style="width: 330px">
                            <el-col :span="11">
                                <el-date-picker placeholder="请选择日期" v-model="navForm.startInflowTime" type="date" value-format="yyyy-MM-dd" style="width: 100%" clearable> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker placeholder="请选择日期" v-model="navForm.endInflowTime" type="date" value-format="yyyy-MM-dd" style="width: 100%" clearable> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="案件发生地：">
                        <el-cascader ref="cascaderRef" v-if="tmpCityOptions.length > 0" v-model="cityStr" :props="cityProps" placeholder="请选择" @change="handleSelectCost" filterable clearable></el-cascader>
                        <el-select v-else v-model="cityStr" placeholder="请选择"> </el-select>
                    </el-form-item>
                    <el-form-item label="筛选逻辑：">
                        <el-select v-model="navForm.rapidSearchId" placeholder="请输入" ref="template" filterable clearable @change="selectRapidChange">
                            <el-option v-for="item in rapidOptions" :key="item.id" :label="item.name" :value="item.id">
                                <span style="float: left" class="span-style">{{ item.name }}</span>
                                <span style="float: right" class="span-style-delete" v-if="!item.systemSearchFlag" @click.stop="deleteItem(item)"><i class="el-icon-delete" /></span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchCaseQuality(true)" style="margin-left: 0.2rem">查询</el-button>
                        <el-button type="primary" :disabled="tableData.length < 1" @click="downloadFile" style="margin-left: 0.2rem">下载</el-button>
                        <el-button type="primary" :disabled="tableData.length < 1" @click="enterDialog" style="margin-left: 0.2rem">导入质检案件</el-button>
                        <el-button type="primary" :disabled="tableData.length < 1" @click="saveRapidFlag = true" style="margin-left: 0.2rem">保存筛选条件</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 表格 -->
            <div class="main">
                <div class="table_box public_box">
                    <el-table ref="filterTable" :data="tableData" v-loading="loading" style="width: 100%" height="370" @select-all="selectAll" @select="selectRow">
                        <el-table-column type="selection" width="55"> </el-table-column>
                        <el-table-column label="案件号" fixed="left" width="210">
                            <template slot-scope="scope">
                                <div class="tooltipText" @click="hrefDetail(scope.row.meituanCaseNo, scope.row.caseNo)">
                                    <overflow-txt :txt="scope.row.caseNo"></overflow-txt>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="meituanCaseNo" label="美团案件号" fixed="left" width="210"></el-table-column>
                        <el-table-column prop="totalPayAmount" label="案件金额" width="210"></el-table-column>
                        <el-table-column prop="category3" label="车损质检结果" width="100"></el-table-column>
                        <el-table-column prop="injuryCategory1" label="人伤质检结果" width="160"></el-table-column>
                        <el-table-column prop="orderDesc" label="优先级"></el-table-column>
                        <el-table-column prop="vehicleQuality" label="车损质检状态" width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.vehicleQuality ? '已质检' : '未质检' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="injuryQuality" label="人伤质检状态" width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.injuryQuality ? '已质检' : '未质检' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="inputPerson" label="录入人"></el-table-column>
                        <el-table-column prop="vehicleQualityUser" label="车损质检员" width="160"></el-table-column>
                        <el-table-column prop="injuryQualityUser" label="人伤质检员" width="160"></el-table-column>
                        <el-table-column label="标签" width="90">
                            <template slot-scope="scope">
                                <template v-if="scope.row.tags.length > 0">
                                    <el-tag v-for="item in scope.row.tags" :key="item.id" type="primary">{{ item.tagDesc }}</el-tag>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column prop="inflowTime" label="流入时间" width="140"></el-table-column>
                        <el-table-column prop="vehicleQualityTime" label="车损质检时间" width="140"></el-table-column>
                        <el-table-column prop="injuryQualityTime" label="人伤质检时间" width="140"></el-table-column>
                        <!-- <el-table-column label="操作" fixed="right" width="140">
                            <template slot-scope="scope">
                                <span class="a_color" @click="operateCase(scope.row, 'chesun')">车损质检</span>
                                <span class="a_color" style="margin-left: 15px" @click="operateCase(scope.row, 'renshang')">人伤质检</span>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
                </div>
            </div>
        </div>
        <!-- 保存筛选条件弹窗 -->
        <el-dialog title="保存筛选条件" :visible.sync="saveRapidFlag" width="30%" append-to-body center @close="saveRapidFn(0)">
            <el-form :model="rapidForm" :rules="rapidRules" ref="rapidForm" label-width="130px">
                <el-form-item label="筛选条件名称：" prop="name">
                    <el-input v-model="rapidForm.name" style="width: 90%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="saveRapidFn(0)">取 消</el-button>
                <el-button type="primary" @click="saveRapidFn(1)">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 录入弹窗 -->
        <el-dialog title="录入案件" :visible.sync="dialogVisible" width="30%" append-to-body center :before-close="beforeClose1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="任务标签：" prop="tagCode">
                    <el-select v-model="ruleForm.tagCode" placeholder="请选择任务标签" style="width: 90%">
                        <template v-for="item in caseQualityTagOption">
                            <el-option :key="item.id" :label="item.dictName" :value="item.metaData"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="优先级：" prop="orderCode">
                    <el-select v-model="ruleForm.orderCode" placeholder="请选择优先级" style="width: 90%">
                        <template v-for="item in caseQualityOrderOption">
                            <el-option :key="item.id" :label="item.dictName" :value="item.metaData"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="案件号：" prop="desc">
                    <el-input style="width: 90%" type="textarea" :rows="4" v-model="ruleForm.desc"></el-input>
                    <p style="color: red">注：每行一个案件号</p>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose1">取 消</el-button>
                <el-button type="primary" @click="submitRuleForm">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 质检弹窗 -->
        <el-dialog class="zhi-dialog" :title="switchStr == 'renshang' ? '人伤质检' : '车损质检'" :visible.sync="zhiDialogVisible" width="750px" append-to-body center @close="beforeClose">
            <el-form class="dialog-box autoC clearfix" :model="zhiRuleForm" :rules="zhiRules" ref="zhiRuleForm" label-width="120px">
                <div :class="zhiRuleForm.injuryRiskCase || zhiRuleForm.vehicleRiskCase ? 'dialog-left fl' : 'dialog-left autoC'">
                    <el-form-item label="案件号：">
                        <span>{{ zhiRuleForm.caseNo }}</span>
                    </el-form-item>
                    <template v-if="switchStr == 'renshang'">
                        <el-form-item label="是否风险案件：" prop="injuryRiskCase">
                            <el-radio-group v-model="zhiRuleForm.injuryRiskCase">
                                <el-radio :label="false">否</el-radio>
                                <el-radio :label="true">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <el-form-item label="是否风险案件：" prop="vehicleRiskCase">
                            <el-radio-group v-model="zhiRuleForm.vehicleRiskCase">
                                <el-radio :label="false">否</el-radio>
                                <el-radio :label="true">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    <template v-if="switchStr == 'renshang' && zhiRuleForm.injuryRiskCase">
                        <el-form-item label="可减损金额：" prop="injuryReduceAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.injuryReduceAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                        <el-form-item label="估损金额：" prop="injuryEstimateAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.injuryEstimateAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                    </template>
                    <template v-else-if="switchStr == 'chesun' && zhiRuleForm.vehicleRiskCase">
                        <el-form-item label="可减损金额：" prop="vehicleReduceAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.vehicleReduceAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                        <el-form-item label="估损金额：" prop="vehicleEstimateAmount">
                            <el-input class="resetLineHeight" v-model.number="zhiRuleForm.vehicleEstimateAmount" type="number" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
                        </el-form-item>
                    </template>
                    <el-form-item label="备注项：" prop="remark">
                        <el-input :class="zhiRuleForm.riskCase ? 'resetLineHeight' : 'resetLineHeight'" v-model="zhiRuleForm.remark" type="textarea" :rows="4"></el-input>
                    </el-form-item>
                </div>
                <div class="dialog-right fr" v-if="switchStr == 'renshang' && zhiRuleForm.injuryRiskCase">
                    <div class="right-div">
                        <el-form-item class="newLeft" label="人伤风险点：" prop="injuryCategory1" required>
                            <div class="right-div">
                                <el-select v-model="zhiRuleForm.injuryCategory1" placeholder="一级风险点" clearable>
                                    <template v-for="item in riskTypeOption">
                                        <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                                    </template>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item class="newLeft" label="" prop="injuryCategory2" required>
                            <div class="right-div">
                                <el-select v-model="zhiRuleForm.injuryCategory2" placeholder="二级风险点" multiple clearable>
                                    <template v-for="item in riskTypeOption2">
                                        <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                                    </template>
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="dialog-right fr" v-else-if="switchStr == 'chesun' && zhiRuleForm.vehicleRiskCase">
                    <el-form-item class="none-left" label="车损风险点：" prop="category3" required> </el-form-item>
                    <div class="right-div">
                        <el-select v-model="zhiRuleForm.category1" placeholder="一级风险" @change="getCategory(1)">
                            <template v-for="item in category1Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="right-div">
                        <el-select v-model="zhiRuleForm.category2" placeholder="二级风险" @change="getCategory(2)">
                            <template v-for="item in category2Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="right-div">
                        <el-select v-model="zhiRuleForm.category3" placeholder="具体项" multiple clearable>
                            <template v-for="item in category3Option">
                                <el-option :key="item.id" :label="item.dictName" :value="item.dictName"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitZhiForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import CaseInfo from '@/components/staging/index';

let cityOptions = [];
let _this = null;
export default {
    components: {
        CaseInfo
    },
    data() {
        return {
            selectBeforeFlag: 999,
            isToggleAllSelection: false,
            selectAllFlag: 1, // 1 全选  2不是全选  0全取消
            cityStr: '',
            provinceObj: {},
            tmpCityOptions: [],
            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;

                    switch (level) {
                        case 0:
                            resolve(cityOptions);

                            break;
                        case 1:
                            _this.findCity(node.value).then((res) => {
                                resolve(res);
                            });

                            break;
                        case 2:
                            _this.findDistrict(node.value).then((res) => {
                                resolve(res);
                            });
                            break;
                        default:
                            resolve();
                            break;
                    }
                }
            },
            rapidOptions: [],
            rapidOptionsObj: {},
            selectIds: [],
            descIds: [],
            selectTableDataObj: {},
            formData: {
                value: ''
            },
            saveRapidFlag: false,
            rapidForm: {
                name: '',
                searchDto: {}
            },
            rapidRules: {
                name: [{ required: true, message: '请填写筛选条件名称', trigger: 'change' }]
            },
            navForm: {
                rapidSearchId: '',
                caseNo: '',
                tagCode: '',
                orderCode: '',
                qualityStatus: '',
                startInflowTime: '2024-08-14',
                // startInflowTime: '',
                endInflowTime: '',
                startQualityTime: '',
                endQualityTime: '',
                meituanCaseNo: '',
                deliveryName: '',
                deliveryIdCard: '',
                accidentProvince: '',
                accidentCity: '',
                accidentDistrict: '',
                geAmount: '',
                leAmount: '',
                accidentType: '',
                category3: [],
                injuryCategory2: [],
                // startTagTime: '',
                // endTagTime: '',
                size: 10,
                page: 1,
                total: 0
            },
            ruleForm: {
                tagCode: '',
                orderCode: '',
                desc: '',
                caseNos: []
            },
            totalPage: 0,
            currentPage: 10,
            dialogVisible: false,
            loading: false,
            caseInfo: false,
            tableData: [],
            options: [],
            caseRowData: {},
            caseJobAggInfoVo: {},
            aggUserCaseJobVos: {},
            aggUserCaseJobVosOutside: {},
            statisticsFlag: false,
            dialogVisible: false,
            rules: {
                orderCode: [{ required: true, message: '请选择任务标签', trigger: 'change' }],
                tagCode: [{ required: true, message: '请选择优先级', trigger: 'change' }],
                desc: [{ required: true, message: '请填写案件号', trigger: 'blur' }]
            },
            caseQualityOrderOption: [],
            caseQualityTagOption: [],
            zhiDialogVisible: false,
            zhiRuleForm: {},
            zhiRules: {
                reduceAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                estimateAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                totalPayAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                injuryReduceAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                injuryEstimateAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                vehicleReduceAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                vehicleEstimateAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
                injuryCategory1: [{ required: true, message: '请选择风险点', trigger: 'change' }],
                injuryCategory2: [{ required: true, message: '请选择风险点', trigger: 'change' }],
                category3: [{ required: true, message: '请选择完整风险点', trigger: 'change' }],
                desc: [{ required: true, message: '请填写案件号', trigger: 'blur' }]
            },
            category1Option: [],
            category2Option: [],
            category3Option: [],
            zhiRuleForm: {
                caseNo: '',
                riskCase: '',
                reduceAmount: '',
                estimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                totalPayAmount: '',
                vehicleRiskCase: false,
                injuryRiskCase: false,
                category1: '',
                category2: '',
                category3: '',
                injuryCategory1: '',
                injuryCategory2: [],
                remark: null
            },
            userOptions: [],
            zhiOptions: [
                { label: '是', value: true },
                { label: '否', value: false }
            ],
            accidentTypeOption: [],
            tmpAccidentTypeOption: [],
            qualityInspectionOption: [],
            riskTypeOption: [],
            riskTypeOption2: [],
            switchStr: ''
        };
    },
    mounted() {
        _this = this;
        this.tmpCityOptions = cityOptions;
        // 省
        this.findProvince();
        // 快速筛选
        this.rapidSearch();
        // 获取案件列表
        this.searchCaseQuality(true);
        // 优先级
        this.getCaseQualityOrder();
        // 任务标签
        this.getCaseQualityTag();
        this.getCategory(0);
        this.getCategory(2);
        this.getResponsibleExaminer();
        this.getCaseQualityHumanInjuryRisk();
        // 事故类型 枚举
        this.getPublicEnum('CASE_QUALITY_ACCIDENT_TYPE', 'accidentTypeOption');
        // 质检状态 枚举
        this.getPublicEnum('CASE_QUALITY_STATUS', 'qualityInspectionOption');
        // 人伤风险类型1 枚举
        this.getPublicEnum('CASE_QUALITY_HUMAN_INJURY_RISK_01', 'riskTypeOption');
        // 人伤风险类型2 枚举
        this.getPublicEnum('CASE_QUALITY_HUMAN_INJURY_RISK_02', 'riskTypeOption2');
    },
    methods: {
        findProvince() {
            cityOptions = [];
            this.instance.post('/area/findProvince', {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.provinceObj = {};
                    data.data.forEach((item) => {
                        item.value = item.areaCode;
                        item.label = item.areaName;
                        this.provinceObj[item.areaName] = item.areaCode;
                    });
                    cityOptions = data.data;
                    this.tmpCityOptions = cityOptions;
                }
            });
        },
        findCity(code) {
            return new Promise((resolve, reject) => {
                this.instance.post('/area/findCity/' + code, {}).then((res) => {
                    let data = res.data;
                    if (data.code == 1 && data.data) {
                        data.data.forEach((item) => {
                            item.value = item.areaCode;
                            item.label = item.areaName;
                        });
                        resolve(data.data);
                    }
                });
            });
        },
        findDistrict(code) {
            return new Promise((resolve, reject) => {
                this.instance.post('/area/findDistrict/' + code, {}).then((res) => {
                    let data = res.data;
                    if (data.code == 1 && data.data) {
                        data.data.forEach((item) => {
                            item.value = item.areaCode;
                            item.label = item.areaName;
                            item.leaf = true;
                        });
                        resolve(data.data);
                    }
                });
            });
        },
        handleSelectCost() {
            if (this.$refs.cascaderRef && this.$refs.cascaderRef.getCheckedNodes()[0]) {
                let pathLabels = this.$refs.cascaderRef.getCheckedNodes()[0].pathLabels;
                this.navForm.accidentProvince = pathLabels[0];
                this.navForm.accidentCity = pathLabels[1];
                this.navForm.accidentDistrict = pathLabels[2];
            }
        },
        saveRapidFn(submitFlag) {
            if (submitFlag) {
                this.rapidForm.searchDto = this.navForm;
                this.rapidForm.page = 1;
                this.rapidForm.size = 10;
                this.$refs.rapidForm.validate((valid) => {
                    valid &&
                        this.instance.post('/caseQuality/saveRapidSearch', this.rapidForm).then((res) => {
                            let data = res.data;
                            if (data.code == 1) {
                                this.rapidSearch();
                                this.$message.success('保存成功！');
                                this.saveRapidFlag = false;
                            } else {
                                this.$message.error(data.message);
                            }
                        });
                });
            } else {
                this.$refs.rapidForm.resetFields();
                this.rapidForm = {
                    name: '',
                    searchDto: {}
                };
                this.saveRapidFlag = false;
            }
        },
        rapidSearch() {
            this.instance.post('/caseQuality/rapidSearch', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.rapidOptions = data.data;
                    this.rapidOptions.forEach((item) => {
                        this.rapidOptionsObj[item.id] = item;
                    });
                }
            });
        },
        selectRapidChange(val) {
            let tmpNavForm = JSON.parse(JSON.stringify(this.navForm));
            if (this.rapidOptionsObj[val].systemSearchFlag) {
                this.navForm = {
                    rapidSearchId: '',
                    caseNo: '',
                    tagCode: '',
                    orderCode: '',
                    qualityStatus: '',
                    startInflowTime: '',
                    endInflowTime: '',
                    startQualityTime: '',
                    endQualityTime: '',
                    meituanCaseNo: '',
                    deliveryName: '',
                    deliveryIdCard: '',
                    accidentProvince: '',
                    accidentCity: '',
                    accidentDistrict: '',
                    geAmount: '',
                    leAmount: '',
                    accidentType: '',
                    category3: [],
                    injuryCategory2: [],
                    // startTagTime: '',
                    // endTagTime: '',
                    size: 10,
                    page: 1,
                    total: 0
                };
            } else {
                this.navForm = this.rapidOptionsObj[val].searchDto;
            }
            this.navForm.rapidSearchId = val;
            this.navForm.size = tmpNavForm.size;
            this.navForm.page = tmpNavForm.page;
            this.navForm.total = tmpNavForm.total;
        },
        // 案件号清单
        searchCaseNos() {},
        deleteItem(item) {
            this.$confirm('确定要删除' + "' " + item.name + " '" + '吗？', '删除', {
                type: 'warning'
            })
                .then(() => {
                    this.instance.post('/caseQuality/delRapidSearch', { id: item.id }).then((res) => {
                        let data = res.data;
                        if (data.code == 1) {
                            this.rapidSearch();
                            this.$message.success('删除成功！');
                        } else {
                            this.$message.error(data.message);
                        }
                    });
                })
                .catch(() => {});
        },
        hrefDetail(caseNo, oldCaseNo) {
            let url = 'https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/';
            if (oldCaseNo.indexOf('old') != -1) {
                url = 'https://awp.meituan.com/ffe/insurance-claim-platform/index/index.html?code=MGAr4r&state=dx_auth_interceptor#/case?caseId=';
            } else {
                url = 'https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/';
            }
            window.open(url + caseNo);
        },
        download() {
            let queryForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance({
                method: 'post',
                url: '/caseQuality/downloadCaseQuality',
                data: queryForm,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"text/csv;charset=UTF-8",
                }
            })
                .then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '质检案件.xls');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        qualityChange(val) {
            let tmpOption = [];
            this.navForm.accidentType = '';
            this.qualityInspectionOption.forEach((item) => {
                if (item.metaData == val) {
                    this.accidentTypeOption.forEach((items) => {
                        if (items.metaData == item.expandInfo) {
                            tmpOption.push(items);
                        }
                    });
                }
            });
            this.tmpAccidentTypeOption = tmpOption;
        },
        getCaseQualityHumanInjuryRisk() {
            this.instance.get('/caseQuality/getCaseQualityHumanInjuryRisk', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                }
            });
        },
        getResponsibleExaminer() {
            this.instance.post('/caseJobCheck/searchCommitJobUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.value = item.username;
                        item.label = item.nikeName;
                    });
                    this.userOptions = data.data;
                    this.userOptions.unshift({ value: 'all', label: '全部' });
                }
            });
        },
        beforeClose1() {
            this.dialogVisible = false;
            this.$refs.ruleForm.resetFields();
        },
        beforeClose() {
            this.zhiDialogVisible = false;
            this.$refs.zhiRuleForm.resetFields();
            this.zhiRuleForm = {
                caseNo: '',
                riskCase: '',
                reduceAmount: '',
                estimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                injuryReduceAmount: '',
                injuryEstimateAmount: '',
                totalPayAmount: '',
                vehicleRiskCase: false,
                injuryRiskCase: false,
                category1: '',
                category2: '',
                category3: '',
                injuryCategory1: '',
                injuryCategory2: [],
                remark: null,
                riskCase: false
            };
        },
        submitZhiForm() {
            this.$refs.zhiRuleForm.validate((validate) => {
                if (validate) {
                    this.instance.post(this.switchStr == 'chesun' ? '/caseQuality/vehicleQuality' : '/caseQuality/injuryQuality', this.zhiRuleForm).then((res) => {
                        let data = res.data;
                        if (data.code == 1) {
                            this.searchCaseQuality();
                            this.$message.success('当前案件质检成功');
                            this.beforeClose();
                        } else {
                            this.$message.error(data.message);
                        }
                    });
                }
            });
        },
        getCategory(num) {
            switch (num) {
                case 1:
                    this.zhiRuleForm.category2 = '';
                    this.zhiRuleForm.category3 = '';
                    break;
                case 2:
                    this.zhiRuleForm.category3 = '';
                    break;

                default:
                    break;
            }

            this.getCaseQualityCategory(num);
        },
        getCaseQualityCategory(num) {
            this.instance.get(`/caseQuality/getCaseQualityCategory${num + 1}`, { params: { ['category' + num]: this.zhiRuleForm['category' + num] } }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.metaData = Number(item.metaData);
                    });
                    this['category' + (num + 1) + 'Option'] = data.data;
                }
            });
        },
        operateCase(row, name) {
            this.switchStr = name;
            this.zhiDialogVisible = true;
            if (this.switchStr == 'renshang') {
                this.zhiRuleForm = {
                    id: row.id,
                    caseNo: row.caseNo,
                    injuryRiskCase: row.injuryRiskCase ? row.injuryRiskCase : false,
                    reduceAmount: row.reduceAmount ? row.reduceAmount : '',
                    estimateAmount: row.estimateAmount ? row.estimateAmount : '',
                    totalPayAmount: row.totalPayAmount ? row.totalPayAmount : '',
                    injuryCategory1: row.injuryCategory1 ? row.injuryCategory1 : '',
                    injuryCategory2: row.injuryCategory2 ? row.injuryCategory2 : [],
                    injuryReduceAmount: row.injuryReduceAmount ? row.injuryReduceAmount : '',
                    injuryEstimateAmount: row.injuryEstimateAmount ? row.injuryEstimateAmount : '',
                    vehicleReduceAmount: row.vehicleReduceAmount ? row.vehicleReduceAmount : '',
                    vehicleEstimateAmount: row.vehicleEstimateAmount ? row.vehicleEstimateAmount : '',
                    remark: row.remark ? row.remark : '',
                    riskCase: false
                };
            } else {
                this.zhiRuleForm = {
                    id: row.id,
                    caseNo: row.caseNo,
                    vehicleRiskCase: row.vehicleRiskCase ? row.vehicleRiskCase : false,
                    category1: row.category1 ? row.category1 : '',
                    category2: row.category2 ? row.category2 : '',
                    category3: row.category3 ? row.category3 : '',
                    reduceAmount: row.reduceAmount ? row.reduceAmount : '',
                    estimateAmount: row.estimateAmount ? row.estimateAmount : '',
                    totalPayAmount: row.totalPayAmount ? row.totalPayAmount : '',
                    injuryCategory1: row.injuryCategory1 ? row.injuryCategory1 : '',
                    injuryCategory2: row.injuryCategory2 ? row.injuryCategory2 : [],
                    injuryReduceAmount: row.injuryReduceAmount ? row.injuryReduceAmount : '',
                    injuryEstimateAmount: row.injuryEstimateAmount ? row.injuryEstimateAmount : '',
                    vehicleReduceAmount: row.vehicleReduceAmount ? row.vehicleReduceAmount : '',
                    vehicleEstimateAmount: row.vehicleEstimateAmount ? row.vehicleEstimateAmount : '',
                    remark: row.remark ? row.remark : '',
                    riskCase: false
                };
                if (row.category1) {
                    this.getCaseQualityCategory(1);
                }
                if (row.category2) {
                    this.getCaseQualityCategory(2);
                }
            }
        },
        submitRuleForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.importQualityCase();
                }
            });
        },
        importQualityCase() {
            if (this.ruleForm.desc.length > 1) {
                this.ruleForm.caseNos = this.ruleForm.desc.split('\n');
                this.instance.post('/caseQuality/importQualityCase', this.ruleForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('案件导入成功');
                        this.searchCaseQuality();
                        this.beforeClose1();
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.$message.error('请输入案件号');
            }
        },
        searchCaseQuality(btnFlag) {
            this.navForm.tagCases = null;
            let queryForm = JSON.parse(JSON.stringify(this.navForm));
            queryForm.page = btnFlag ? 1 : queryForm.page;
            queryForm.size = btnFlag ? 10 : queryForm.size;
            queryForm.accidentType = queryForm.accidentType ? Number(queryForm.accidentType) : '';
            queryForm.qualityStatus = queryForm.qualityStatus ? Number(queryForm.qualityStatus) : '';

            // 点击查询时，默认全选
            if (btnFlag) {
                this.selectTableDataObj = {};
                this.selectAllFlag = 1;
                this.queryAllCase(true);
            }

            this.instance.post('/caseQuality/searchRapidCaseQuality', queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data.list;
                    this.navForm.size = data.data.size;
                    this.navForm.page = data.data.current;
                    this.navForm.total = data.data.total;
                    this.$nextTick(() => {
                        this.$refs.filterTable && this.$refs.filterTable.doLayout();
                    });

                    this.setTableSelect();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        setTableSelect() {
            // 全选
            if (this.selectBeforeFlag >= 1) {
                this.isToggleAllSelection = true;
                this.$refs.filterTable.toggleAllSelection();
                setTimeout(() => {
                    this.isToggleAllSelection = false;
                }, 600);
            } else if (this.selectBeforeFlag == 0) {
                this.$refs.filterTable.clearSelection();
            }

            setTimeout(() => {
                let currentSelectObj = this.selectTableDataObj[this.navForm.page];
                if (currentSelectObj) {
                    let currentSelectFlag = currentSelectObj.selectFlag;
                    let cancelSelectData = currentSelectObj.selectId;

                    // 存储结果的数组
                    var indices = [];

                    cancelSelectData.forEach((item) => {
                        for (let i = 0; i < this.tableData.length; i++) {
                            // 判断 caseNo 属性是否等于 item
                            if (this.tableData[i].caseNo == item) {
                                indices.push(i);
                            }
                        }
                    });

                    this.$nextTick(() => {
                        // 2 既不是全选，也不是取消全选
                        if (currentSelectFlag == 2) {
                            this.$refs.filterTable.clearSelection();

                            this.tableData.forEach((item, index) => {
                                if (this.selectBeforeFlag == 0) {
                                    if (indices.includes(index)) {
                                        // 选中索引内的
                                        this.$refs.filterTable.toggleRowSelection(this.tableData[index], true);
                                    }
                                } else if (this.selectBeforeFlag == 1) {
                                    if (!indices.includes(index)) {
                                        // 选中索引外的
                                        this.$refs.filterTable.toggleRowSelection(this.tableData[index], true);
                                    }
                                }
                            });
                        }
                    });
                }
            }, 500);
        },
        getCaseQualityOrder() {
            this.instance.get('/caseQuality/getCaseQualityOrder', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.metaData = Number(item.metaData);
                    });
                    this.caseQualityOrderOption = data.data;
                }
            });
        },
        getCaseQualityTag() {
            this.instance.get('/caseQuality/getCaseQualityTag', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.metaData = Number(item.metaData);
                    });
                    this.caseQualityTagOption = data.data;
                }
            });
        },
        enterDialog() {
            this.dialogVisible = true;
            this.ruleForm.desc = '';
            if (this.selectIds.length > 0) {
                this.selectIds.forEach((item, index) => {
                    if (index != this.selectIds.length - 1) {
                        this.ruleForm.desc += item + '\n';
                    } else {
                        this.ruleForm.desc += item;
                    }
                });
            }
        },
        handleSizeChange(val) {
            this.navForm.size = val;
            this.searchCaseQuality();
        },
        handleCurrentChange(val) {
            this.navForm.page = val;
            this.searchCaseQuality();
        },
        selectAll(val) {
            if (this.isToggleAllSelection) {
                return;
            }
            if (val.length == 0) {
                this.selectTableDataObj = {};
                this.selectIds = [];
                this.selectAllFlag = 0;
            } else {
                this.selectAllFlag = 1;
                this.queryAllCase(true);
                this.selectTableDataObj = {};
            }
            this.selectBeforeFlag = this.selectAllFlag;
        },
        selectRow(selection, row) {
            if (Object.keys(this.selectTableDataObj).length == 0) {
                this.selectBeforeFlag = this.selectAllFlag;
            }

            if (selection.length == 0) {
                this.selectAllFlag = 0;
            } else if (selection.length != this.navForm.size) {
                this.selectAllFlag = 2;
                this.selectIds = this.selectIds.filter((item) => item !== row.caseNo);

                if (this.selectTableDataObj[this.navForm.page]) {
                    this.selectTableDataObj[this.navForm.page].selectId.push(row.caseNo);
                } else {
                    this.selectTableDataObj[this.navForm.page] = {
                        selectId: [row.caseNo],
                        selectFlag: 2
                    };
                }

                selection.forEach((item) => {
                    this.selectIds.push(item.caseNo);
                });
                this.selectIds = [...new Set(this.selectIds)];
            } else {
                this.selectAllFlag = 1;
            }
            this.$forceUpdate();
        },
        // 获取全部案件号清单
        queryAllCase(selectAllFlag) {
            this.navForm.tagCases = [];
            this.instance.post('/caseQuality/searchRapidCaseQualityCaseNos', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.selectIds = data.data;
                }
            });
        },
        downloadFile(type) {
            this.selectIds = [...new Set(this.selectIds)];
            this.navForm.tagCases = this.selectIds;

            this.instance({
                method: 'post',
                url: `/caseQuality/downloadRapidCaseQuality`,
                data: this.navForm,
                responseType: 'blob'
            }).then((res) => {
                this[`downloadFlag${type}`] = false;
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(res.headers.filename));
                document.body.appendChild(link);
                link.click();
            });
        }
    }
};
</script>
<style lang="less">
@import '../../assets/css/table_public.css';

#chartDom1,
#chartDom2,
#chartDom3 {
    width: 400px;
    height: 200px;
}
.show_txt {
    padding-top: 17px;
    font-size: 14px;
    font-weight: 800;
    line-height: 23px;
    margin-left: 10%;
    position: absolute;
    left: 46%;
}
.task_cards {
    display: flex;
    justify-content: space-around;
}
.task_card {
    width: 340px;
    height: 85px;
    line-height: 85px;
    background: rgb(0, 192, 239);
    border-radius: 5px;
}
.card2 {
    background: rgb(0, 160, 87);
}
.card3 {
    background: rgb(241, 155, 18);
}
.task_card p {
    color: white;
    font-size: 20px;
    text-align: center;
}
.task_card .card_txt {
    line-height: normal;
    font-size: 12px;
    color: black;
}
.task_card .card_font {
    color: white;
    font-size: 20px;
}
.task_card .card_hot {
    color: blue;
    font-weight: 700;
    font-size: 36px;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.clearFix {
}
.clearFix:after {
    content: '';
    display: block;
    clear: both;
}
.dialog-left {
    width: 49%;
    min-width: 350px;
}
.dialog-left .el-form-item__content {
    text-align: left;
}
.dialog-right .right-div {
    margin-bottom: 20px;
}
/deep/.el-form-item__content {
    margin-left: 80px !important;
}
.none-left {
    margin-left: 0 !important;
}
.none-left .el-form-item__error {
    margin-left: -120px;
}
.none-left label {
    text-align: left !important;
}
.newLeft {
    .right-div {
        margin-bottom: 5px;
    }
}
.resetLineHeight /deep/.el-input__inner {
    line-height: 1px !important;
}
.zhi-dialog .el-dialog__footer {
    margin-top: -20px;
}
</style>