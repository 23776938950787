<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">
                    投诉案件详情
                    <el-tag type="warning" style="margin-left: 5px" v-if="caseDetailData && caseDetailData.suspendedFlag">挂起</el-tag>
                </div>
            </el-col>
        </el-row>
        <div class="card_content">
            <el-row>
                <el-col :span="24">
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">投诉编号：{{ caseDetailData && caseDetailData.complaintNumber ? caseDetailData.complaintNumber : '--' }}</el-col>
                        <!-- @blur="queryPolicyInfo" -->
                        <el-col class="col_txt" :span="7" :offset="2" v-if="editInfoFlag">保单号：<el-input class="inputNo" maxlength="50" v-model="editForm.policyNo" @change="queryPolicyInfo"></el-input></el-col>
                        <el-col class="col_txt" :span="7" :offset="2" v-else>保单号：{{ caseDetailData && caseDetailData.policyNo ? caseDetailData.policyNo : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">渠道：{{ caseDetailData && caseDetailData.complaintChannel ? caseDetailData.complaintChannel : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">投诉人：{{ caseDetailData && caseDetailData.complaintPerson ? caseDetailData.complaintPerson : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">投诉人身份：{{ caseDetailData && caseDetailData.complaintIdCard ? caseDetailData.complaintIdCard : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">协助处理人：{{ caseDetailData && caseDetailData.assistingHandler ? caseDetailData.assistingHandler : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">责任机构：{{ caseDetailData && caseDetailData.liableOrganization ? caseDetailData.liableOrganization : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">投诉电话1：{{ caseDetailData && caseDetailData.complaintPhone ? caseDetailData.complaintPhone : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">投诉电话2：{{ caseDetailData && caseDetailData.complaintPhone2 ? caseDetailData.complaintPhone2 : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="24">投诉内容：{{ caseDetailData && caseDetailData.complaintContent ? caseDetailData.complaintContent : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="24">诉求原因：{{ caseDetailData && caseDetailData.complaintCause ? caseDetailData.complaintCause : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">涉诉机构：{{ caseDetailData && caseDetailData.complaintOrganization ? caseDetailData.complaintOrganization : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">责任人：{{ caseDetailData && caseDetailData.liablePerson ? caseDetailData.liablePerson : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">核赔人：{{ caseDetailData && caseDetailData.checkIndemnityPerson ? caseDetailData.checkIndemnityPerson : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7" v-if="editInfoFlag"
                            >涉诉省：
                            <el-select class="inputNo" v-model="editForm.province" filterable clearable placeholder="请选择" @change="enterProvinceChange">
                                <el-option v-for="item in provinceOptions" :key="item.areaName" :label="item.areaName" :value="item.areaName"> </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="col_txt" :span="7" v-else>涉诉省：{{ caseDetailData && caseDetailData.province ? caseDetailData.province : '--' }}</el-col>

                        <el-col class="col_txt" :span="7" :offset="2" v-if="editInfoFlag"
                            >涉诉市：
                            <el-select class="inputNo" v-model="editForm.city" filterable clearable placeholder="请选择">
                                <el-option v-for="item in cityOptions" :key="item.areaName" :label="item.areaName" :value="item.areaName"> </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="col_txt" :span="7" :offset="2" v-else>涉诉市：{{ caseDetailData && caseDetailData.city ? caseDetailData.city : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">升级情况：{{ caseDetailData && caseDetailData.upgradeSituation ? caseDetailData.upgradeSituation : '--' }}</el-col>
                        <!-- <el-col class="col_txt" :span="7" :offset="1">是否结案：{{ caseDetailData && String(caseDetailData.closeCaseFlag) == 'true' ? '是' : caseDetailData && String(caseDetailData.closeCaseFlag) == 'false' ? '否' : '--' }}</el-col> -->
                    </el-row>

                    <!-- <el-row class="content_row" v-if="editInfoFlag">
                        <el-col class="col_txt" :span="7"
                            >涉诉省：
                            <el-select class="inputNo" v-model="editForm.province" filterable clearable placeholder="请选择" @change="enterProvinceChange">
                                <el-option v-for="item in provinceOptions" :key="item.areaName" :label="item.areaName" :value="item.areaName"> </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="col_txt" :span="7" :offset="2"
                            >涉诉市：
                            <el-select class="inputNo" v-model="editForm.city" filterable clearable placeholder="请选择">
                                <el-option v-for="item in cityOptions" :key="item.areaName" :label="item.areaName" :value="item.areaName"> </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row class="content_row" v-else>
                        <el-col class="col_txt" :span="7">涉诉省：{{ caseDetailData && caseDetailData.province ? caseDetailData.province : '--' }} </el-col>
                        <el-col class="col_txt" :span="7" :offset="2">涉诉市：{{ caseDetailData && caseDetailData.city ? caseDetailData.city : '--' }} </el-col>
                    </el-row> -->
                    <template v-if="editInfoFlag">
                        <el-row class="content_row">
                            <el-col class="col_txt" :span="7"
                                >争议案件：
                                <el-radio v-model="editForm.disputedCase" :label="true">是</el-radio>
                                <el-radio v-model="editForm.disputedCase" :label="false">否</el-radio>
                            </el-col>
                            <el-col class="col_txt" :span="7" :offset="2"
                                >索赔金额：
                                <el-input
                                    class="edit_input"
                                    v-model="editForm.claimAmount"
                                    placeholder="请输入内容"
                                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                >
                                </el-input>
                                元
                            </el-col>
                            <el-col class="col_txt" :span="7" :offset="1"
                                >争议金额：
                                <el-input
                                    class="edit_input"
                                    v-model="editForm.disputedAmount"
                                    placeholder="请输入内容"
                                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                >
                                </el-input>
                                元
                            </el-col>
                        </el-row>
                    </template>
                    <template v-else>
                        <el-row class="content_row">
                            <el-col class="col_txt" :span="7">争议案件：{{ caseDetailData && String(caseDetailData.disputedCase) == 'true' ? '是' : caseDetailData && String(caseDetailData.disputedCase) == 'false' ? '否' : '--' }}</el-col>
                            <el-col class="col_txt" :span="7" :offset="2">索赔金额：{{ caseDetailData && caseDetailData.claimAmount ? caseDetailData.claimAmount : '--' }} 元</el-col>
                            <el-col class="col_txt" :span="7" :offset="1">争议金额：{{ caseDetailData && caseDetailData.disputedAmount ? caseDetailData.disputedAmount : '--' }} 元</el-col>
                        </el-row>
                    </template>
                    <el-row class="content_row" v-if="editInfoFlag">
                        <el-col class="col_txt" :span="24"
                            >备注：
                            <el-input v-model="submitRemark" type="textarea" :rows="3" placeholder="请输入内容"> </el-input>
                        </el-col>
                    </el-row>
                    <el-row class="content_row" v-else>
                        <el-col class="col_txt" :span="24">备注：{{ caseDetailData && caseDetailData.remark ? caseDetailData.remark : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        btnMenuData: {
            type: Object,
            default: null
        },
        editInfoFlag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            editForm: {
                disputedCase: false,
                claimAmount: '',
                disputedAmount: ''
            },
            submitRemark: '',
            provinceOptions: [],
            cityOptions: []
        };
    },
    mounted() {
        this.findProvince();
    },
    methods: {
        // 查询保单信息
        queryPolicyInfo() {
            this.instance.post('/complaintInfo/findExistsComplaint', { policyNo: this.editForm.policyNo }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.setEditData(data.data);
                }
            });
        },
        findProvince() {
            this.instance.post('/area/findProvince', {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.provinceOptions = data.data;
                }
            });
        },
        findCity() {
            return;
            let code = this.addCaseForm.province.split('-')[1];
            this.instance.post('/area/findCity/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.cityDisabled = false;
                    this.districtDisabled = false;
                    this.cityOptions = data.data;
                }
            });
        },
        enterProvinceChange(val) {
            this.editForm.city = '';
            this.findCityChange();
        },
        findCityChange() {
            this.instance.post('/area/findCityName/' + this.editForm.province, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.cityOptions = data.data;
                }
            });
        },
        editCaseDetail(update, event, parentData) {
            if (!parentData) {
                this.$emit('getEditDetailForm', 1, parentData);
                return;
            }
            let editFormData = { ...parentData, ...this.editForm, remark: this.submitRemark };

            this.instance.post('/complaintInfo/editComplaint', editFormData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('编辑成功！');
                    this.$emit('saveEditInfo', false);
                } else {
                    this.$message.error(data.msg);
                }
                update && this.$emit('updatePageInfo');
            });
        },
        setEditData(formData) {
            if (!formData) {
                return;
            }

            // 涉诉机构
            // this.editForm.complaintOrganization = formData.complaintOrganization;
            // 责任人
            this.editForm.liablePerson = formData.liablePerson;
            // 升级情况
            this.editForm.upgradeSituation = formData.upgradeSituation;
            // 省
            this.editForm.province = formData.accidentProvince;
            // 市
            setTimeout(() => {
                this.editForm.city = formData.accidentCity;
                this.$forceUpdate();
            }, 100);
        }
    },
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                    this.editForm = {
                        complaintId: val.id,
                        disputedCase: val.disputedCase,
                        claimAmount: val.claimAmount,
                        disputedAmount: val.disputedAmount,
                        complaintOrganization: val.complaintOrganization,
                        closeCaseFlag: val.closeCaseFlag,
                        policyNo: val.policyNo,
                        liablePerson: val.liablePerson,
                        province: val.province,
                        city: val.city
                    };
                    this.submitRemark = val.remark;

                    this.findCityChange();
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_public.css';
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        .content_row {
            .title {
                font-weight: 600;
            }
            .col_title {
                // font-weight: 600;
                max-width: 80px;
            }
            .col_txt {
                line-height: 22px;
                word-break: break-all;
                // border: 1px solid red;
                .edit_input {
                    width: 160px !important;
                }
                .inputNo {
                    width: 200px !important;
                }
            }
            .winRate {
                display: inline-block;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
            .greenColor {
                background: #67c23a;
            }
            .redColor {
                background: red;
            }
        }
    }
}
</style>