<template>
    <div class="wrap">
        <div class="page_title"><b>案件详情</b></div>
        <div class="page_content clearfix">
            <div :class="{ cards_content: 1, cards_left: 1, fl: 1, 'cards_left-width': 1 }">
                <div :class="{ activeBtns: 1 }" v-if="btnMenuData">
                    <!-- <div :class="{ btn_groups: 1, activeBtns: activeBtns }" v-if="btnMenuData"> -->
                    <el-button type="primary" size="mini" v-if="btnMenuData[300] && !editInfoFlag" @click="editInfoFlag = true">编 辑</el-button>
                    <template v-if="btnMenuData[300] && editInfoFlag">
                        <el-button type="primary" size="mini" @click="saveEditInfo(true)">保 存</el-button>
                        <el-button size="mini" @click="saveEditInfo(false)">取 消</el-button>
                    </template>
                    <template v-if="!editInfoFlag">
                        <el-button type="primary" size="mini" v-if="btnMenuData[200]" @click="reassignFlag = true">转 派</el-button>
                        <el-button type="primary" size="mini" v-if="btnMenuData[500]" @click="approvalFlag = true">咨诉审核</el-button>
                        <el-button type="primary" size="mini" v-if="btnMenuData[501]" @click="assessmentReviewFlag = true">核赔审核</el-button>
                        <el-button type="primary" size="mini" v-if="btnMenuData[400]" @click="checkRemark(1)">提交咨诉</el-button>
                        <el-button type="primary" size="mini" v-if="btnMenuData[402]" @click="reviewFlag = true">提交领导</el-button>
                        <el-button type="primary" size="mini" v-if="btnMenuData[401]" @click="checkRemark(2)">提交核赔</el-button>
                        <el-button type="primary" size="mini" v-if="btnMenuData[502]" @click="leaderApprovalFlag = true">领导审核</el-button>
                    </template>
                </div>
                <!-- 诉讼案件详情 -->
                <case-info ref="caseInfoRef" :caseDetailData="caseDetailData" :editInfoFlag="editInfoFlag" :btnMenuData="btnMenuData" @updatePageInfo="updatePageInfo" @getEditDetailForm="getEditDetailForm" @saveEditInfo="saveEditInfo"></case-info>
                <!-- 处理情况 -->
                <case-situation ref="caseSituationRef" :caseDetailData="caseDetailData" :btnMenuData="btnMenuData" @submitFn="submitFn" @getEditDetailForm="getEditDetailForm"></case-situation>
                <!-- 案件材料 -->
                <case-materia :caseDetailData="caseDetailData" :btnMenuData="btnMenuData" @getCaseDetail="getCaseDetail"></case-materia>
            </div>
            <!-- 案件日志 -->
            <div :class="{ cards_right: 1, fr: 1, 'cards_right-width': 1 }">
                <case-log ref="caseLogRef" :caseDetailData="caseDetailData" @hiddenCaseLog="hiddenCaseLog"></case-log>
            </div>
        </div>
        <!-- 审核弹窗 -->
        <el-dialog class="process_popup" title="咨诉审核" :visible.sync="approvalFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="450px">
            <div class="dialog_content">
                <el-form :model="approvalForm" :rules="approvalRules" ref="approvalRef" label-width="120px">
                    <el-form-item label="审核结果：" prop="agree">
                        <el-radio v-model="approvalForm.agree" :label="true">通过</el-radio>
                        <el-radio v-model="approvalForm.agree" :label="false">拒绝</el-radio>
                    </el-form-item>
                    <!-- v-if="!approvalForm.agree" -->
                    <el-form-item label="审核意见：" prop="remark">
                        <el-input v-model="approvalForm.remark" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="approvalFn(true)">提 交</el-button>
                <el-button @click="approvalFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 提交咨诉弹窗 -->
        <el-dialog class="process_popup" title="提交咨诉" :visible.sync="submitFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="450px">
            <!-- <div class="dialog_content">提交后该案件将不可操作 ！确认“提交”案件 ？</div> -->
            <div class="dialog_content">
                <el-form :model="consultForm" :rules="consultRules" ref="consultRef" label-width="130px">
                    <el-form-item label="咨诉岗：" prop="distributeHandlerUser">
                        <el-select v-model="consultForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in counselingUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFn({ flag: 1 })">确 认</el-button>
                <el-button @click="submitFn({ flag: 0 })">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 提交核赔弹窗 -->
        <el-dialog class="process_popup" title="提交核赔" :visible.sync="assessmentFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="450px">
            <div class="dialog_content">
                <el-form :model="assessmentForm" :rules="assessmentRules" ref="assessmentRef" label-width="130px">
                    <el-form-item label="提交核赔审核：" prop="distributeHandlerUser">
                        <el-select v-model="assessmentForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in checkUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="situation" v-if="0">
                        <el-input v-model="assessmentForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFn({ flag: 11 })">提 交</el-button>
                <el-button @click="submitFn({ flag: 0 })">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 提审弹窗 -->
        <el-dialog class="process_popup" title="提交领导" :visible.sync="reviewFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="450px">
            <div class="dialog_content">
                <el-form :model="reviewForm" :rules="reviewRules" ref="reviewRef" label-width="130px">
                    <el-form-item label="提交领导审核：" prop="distributeHandlerUser">
                        <el-select v-model="reviewForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in leaderOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="remark">
                        <el-input v-model="reviewForm.remark" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="reviewFn(true)">提 交</el-button>
                <el-button @click="reviewFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 领导审核弹窗 -->
        <el-dialog class="process_popup" title="领导审核" :visible.sync="leaderApprovalFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="450px">
            <div class="dialog_content">
                <el-form :model="leaderApprovalForm" :rules="leaderApprovalRules" ref="leaderApprovalRef" label-width="120px">
                    <el-form-item label="审核结果：" prop="">
                        <el-radio v-model="leaderApprovalForm.agree" :label="true" @input="agreeChange(1)">通过</el-radio>
                        <el-radio v-model="leaderApprovalForm.agree" :label="false" @input="agreeChange(1)">拒绝</el-radio>
                    </el-form-item>
                    <el-form-item label="审核意见：" prop="remark" v-if="!approvalForm.agree">
                        <el-input v-model="leaderApprovalForm.remark" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="leaderApprovalFn(true)">提 交</el-button>
                <el-button @click="leaderApprovalFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 核赔审核弹窗 -->
        <el-dialog class="process_popup" title="核赔审核" :visible.sync="assessmentReviewFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="450px">
            <div class="dialog_content">
                <el-form :model="assessmentReviewForm1" ref="assessmentReviewRef1" label-width="120px">
                    <el-form-item label="审核结果：" prop="agree">
                        <el-radio v-model="assessmentReviewForm.agree" :label="true" @change="agreeChange1(1)">通过</el-radio>
                        <el-radio v-model="assessmentReviewForm.agree" :label="false" @change="agreeChange1(2)">拒绝</el-radio>
                    </el-form-item>
                </el-form>
                <el-form :model="assessmentReviewForm" :rules="assessmentReviewRules" ref="assessmentReviewRef" label-width="120px">
                    <el-form-item label="审核意见：" prop="remark" v-if="!assessmentReviewForm.agree">
                        <el-input v-model="assessmentReviewForm.remark" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="咨诉岗：" prop="distributeHandlerUser" v-if="assessmentReviewForm.agree">
                        <el-select v-model="assessmentReviewForm.distributeHandlerUser" filterable clearable placeholder="请选择">
                            <el-option v-for="item in counselingUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核意见：" prop="remark1" v-if="assessmentReviewForm.agree">
                        <el-input v-model="assessmentReviewForm.remark" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="assessmentReviewFn(true)">提 交</el-button>
                <el-button @click="assessmentReviewFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 转派 弹窗 -->
        <el-dialog title="转派案件" :visible.sync="reassignFlag" :modal-append-to-body="false" width="400px" center>
            <el-form ref="reassignRef" :model="reassignForm" :rules="reassignRules" label-width="100px">
                <el-form-item label="转派人：" prop="distributeHandlerUser">
                    <el-select v-model="reassignForm.distributeHandlerUser" filterable clearable placeholder="请选择">
                        <el-option v-for="item in complaintCaseUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmReassign(false)">取 消</el-button>
                <el-button type="primary" @click="confirmReassign(true)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import CaseInfo from '@/components/complaint/caseInfo.vue';
import CaseSituation from '@/components/complaint/caseSituation.vue';
import CaseMateria from '@/components/complaint/caseMateria.vue';
import CaseLog from '@/components/complaint/caseLog.vue';
export default {
    components: {
        CaseInfo,
        CaseSituation,
        CaseMateria,
        CaseLog
    },
    props: {
        iframeScrollTop: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activeBtns: false,
            approvalFlag: false,
            submitFlag: false,
            reviewFlag: false,
            assessmentFlag: false,
            assessmentReviewFlag: false,
            leaderApprovalFlag: false,
            reassignFlag: false,
            editInfoFlag: false,
            btnMenuData: null,
            agreeOptions: [
                {
                    value: true,
                    label: '同意'
                },
                {
                    value: false,
                    label: '不同意'
                }
            ],
            caseDetailData: null,
            approvalForm: {
                agree: null,
                remark: ''
            },
            assessmentReviewForm1: {},
            complaintId: '',
            submitForm: {
                unifyInfo: '',
                leavingMarks: ''
            },
            leaderOptions: [],
            checkUserOptions: [],
            reviewForm: {
                distributeHandlerUser: '',
                situation: ''
            },
            reviewRules: {
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            leaderApprovalForm: {
                agree: null,
                remark: ''
            },
            leaderApprovalRules: {
                agree: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                remark: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            assessmentForm: {
                distributeHandlerUser: '',
                situation: ''
            },
            assessmentRules: {
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            assessmentReviewForm: {
                agree: true,
                remark: '',
                distributeHandlerUser: ''
            },
            assessmentReviewRules: {
                agree: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                remark: [{ required: true, message: '请选择', trigger: 'blur' }],
                remark1: [{ message: '请选择', trigger: 'blur' }]
            },
            rolesData: {},
            reassignForm: {
                complaintId: '',
                distributeHandler: '',
                distributeHandlerUser: ''
            },
            reassignRules: {
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
            },
            complaintCaseUserObj: {},
            complaintCaseUserOptions: [],
            complaintCaseUserObj: {},
            counselingUserOptions: [],
            counselingUserObj: {},
            consultForm: {
                remark: '',
                distributeHandlerUser: ''
            },
            consultRules: {
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
            }
        };
    },
    mounted() {
        this.handleUrlQueryData();
        this.getLeaderUser();
        this.getCheckUser();
        this.getRoles();
        this.getComplaintCaseUser();
        this.getCounselingUser();
    },
    computed: {
        approvalRules() {
            let tmpRules = {
                agree: [{ required: true, message: '请选择', trigger: 'blur' }],
                remark: [{ required: true, message: '请填写', trigger: 'blur' }]
            };

            if (this.approvalForm.agree || this.approvalForm.agree == null) {
                tmpRules = {
                    agree: [{ required: true, message: '请选择', trigger: 'blur' }]
                };
            }

            return tmpRules;
        }
    },
    methods: {
        getRoles() {
            this.rolesData = {};
            this.instance.get('/complaintInfo/getRoles', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        this.rolesData[item.name] = item;
                    });
                }
            });
        },
        hiddenCaseLog() {},
        handleUrlQueryData(waitFlag) {
            if (this.$route.query) {
                // 案件详情
                if (this.$route.query.complaintId && !waitFlag) {
                    this.complaintId = this.$route.query.complaintId;
                    this.getCaseDetail(true);
                }

                // 打开复核弹窗
                // if (this.$route.query.examine && this.caseDetailData && !this.caseDetailData.approveMenu && this.btnMenuData && this.btnMenuData[500] && waitFlag) {
                //     this.approvalFlag = true;
                // }
            }
        },
        getComplaintCaseUser() {
            this.instance.post('/complaintInfo/distributeComplaintCaseUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.complaintCaseUserOptions = data.data;
                    this.complaintCaseUserOptions.forEach((item) => {
                        this.complaintCaseUserObj[item.userName] = item.nickName;
                    });
                }
            });
        },
        getCounselingUser() {
            this.instance.post('/complaintInfo/getCounselingUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.counselingUserOptions = data.data;
                    this.counselingUserOptions.forEach((item) => {
                        this.counselingUserObj[item.userName] = item.nickName;
                    });
                }
            });
        },
        updatePageInfo() {
            this.getCaseDetail();
            this.caseDetailBtnTree();
        },
        getCaseDetail(btnMenuFlag) {
            this.caseDetailData = null;
            this.instance
                .post('/complaintInfo/detail', {
                    complaintId: this.complaintId
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.caseDetailData = data.data;
                        this.caseDetailData.disputedCase = Boolean(data.data.disputedCase);
                        if (btnMenuFlag) {
                            this.caseDetailBtnTree();
                        }
                        let stepLists = this.caseDetailData.stepList;
                        stepLists.forEach((re) => {
                            re.remark = re.remark.replace(/\n/g, '<br>');
                        });
                    } else {
                        this.$message.error(data.message);
                    }
                });
        },
        caseDetailBtnTree() {
            this.instance
                .post('/complaintInfo/getMenu', {
                    complaintId: this.complaintId
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.btnMenuData = {};
                        data.data.forEach((item) => {
                            this.btnMenuData[item.code] = item;
                        });
                        setTimeout(() => {
                            this.handleUrlQueryData(true);
                        }, 100);
                    }
                });
        },
        closeCaseFn(confirm) {
            if (confirm) {
                this.$refs.closeCaseRef.validate((valid) => {
                    if (valid) {
                        this.closeCaseInterface();
                    }
                });
            } else {
                this.closeCaseFlag = false;
                // this.$refs.closeCaseRef.resetFields();
            }
        },
        selectChange() {
            this.$forceUpdate();
        },
        approvalFn(confirm) {
            if (confirm) {
                this.$refs.approvalRef.validate((valid) => {
                    if (valid) {
                        this.approvalInterface();
                    }
                });
            } else {
                this.approvalFlag = false;
                this.$refs.approvalRef.resetFields();
            }
        },
        approvalInterface() {
            this.approvalForm.complaintId = this.caseDetailData.id;
            this.instance.post('/complaintInfo/auditComplaint', this.approvalForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.approvalFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        checkRemark(checkType) {
            if (this.$refs.caseInfoRef.editForm.disputedCase && !this.$refs.caseInfoRef.editForm.claimAmount) {
                this.$message.error('请填写索赔金额，再进行操作！');
                return;
            }
            if (this.$refs.caseInfoRef.editForm.disputedCase && !this.$refs.caseInfoRef.editForm.disputedAmount) {
                this.$message.error('请填写争议金额，再进行操作！');
                return;
            }
            if (this.$refs.caseInfoRef.editForm.disputedCase && !this.$refs.caseInfoRef.submitRemark) {
                this.$message.error('请填写备注，再进行操作！');
                return;
            }
            if (checkType == 1) {
                this.submitFlag = true;
            } else {
                this.assessmentFlag = true;
            }
        },
        submitFn(confirmData) {
            switch (confirmData.flag) {
                case 0:
                    this.submitFlag = false;
                    this.assessmentFlag = false;

                    break;
                // 提交咨诉
                case 1:
                    this.$refs.caseSituationRef.validateForm(1);

                    break;
                // 提交核赔
                case 11:
                    this.$refs.caseSituationRef.validateForm(2);

                    break;
                case 2:
                    this.$refs.consultRef.validate((valid) => {
                        if (valid) {
                            confirmData.complaintId = this.caseDetailData.id;
                            confirmData.distributeHandlerUser = this.consultForm.distributeHandlerUser;
                            confirmData.distributeHandler = this.counselingUserObj[confirmData.distributeHandlerUser];
                            this.instance.post('/complaintInfo/commitComplaint', confirmData).then((res) => {
                                let data = res.data;
                                if (data.code == 1) {
                                    this.$message.success('提交成功！');
                                    this.submitFlag = false;
                                    // window.open('', '_self').close();
                                } else {
                                    this.$message.error(data.message);
                                }
                                this.updatePageInfo();
                            });
                        }
                    });

                    break;
                case 22:
                    this.submitAssessmentFn(1, confirmData);
                    break;
                // 咨诉窗口关闭
                case 3:
                    this.submitFlag = confirmData.submitFlag;

                    break;
                // 核赔窗口关闭
                case 33:
                    this.assessmentFlag = confirmData.submitFlag;

                    break;

                default:
                    break;
            }
        },
        submitAssessmentFn(submitFlag, fromData) {
            if (submitFlag) {
                this.$refs.assessmentRef.validate((valid) => {
                    valid && fromData && this.commitCheck(fromData);
                });
            } else {
                this.assessmentFlag = false;
            }
        },
        commitCheck(fromData) {
            let tmpForm = JSON.parse(JSON.stringify(this.assessmentForm));
            this.assessmentForm.complaintId = this.complaintId;
            this.assessmentForm = {
                distributeHandlerUser: tmpForm.distributeHandlerUser,
                situation: tmpForm.situation,
                complaintId: this.complaintId,
                leavingMarks: fromData.leavingMarks,
                unifyInfo: fromData.unifyInfo
            };

            this.instance.post('/complaintInfo/commitCheck', this.assessmentForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('提交成功！');
                    this.submitAssessmentFn(0);
                } else {
                    this.$message.error(data.message);
                    this.submitAssessmentFn(0);
                }
                this.updatePageInfo();
            });
        },
        editInfo(data) {
            this.litigationInfoShow = data;
        },
        getLeaderUser() {
            this.instance.post('/complaintInfo/getLeaderUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.leaderOptions = data.data;
                }
            });
        },
        getCheckUser() {
            this.instance.post('/complaintInfo/getCheckUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.checkUserOptions = data.data;
                }
            });
        },
        reviewFn(submitFlag) {
            if (submitFlag) {
                this.$refs.reviewRef.validate((valid) => {
                    if (valid) {
                        this.reviewInterface();
                    }
                });
            } else {
                this.reviewFlag = false;
                this.$refs.reviewRef.resetFields();
            }
        },
        reviewInterface() {
            this.reviewForm.complaintId = this.caseDetailData.id;
            this.instance.post('/complaintInfo/commitLeader', this.reviewForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.reviewFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        leaderApprovalFn(submitFlag) {
            if (submitFlag) {
                this.$refs.leaderApprovalRef.validate((valid) => {
                    if (valid) {
                        this.auditLeader();
                    }
                });
            } else {
                this.leaderApprovalFlag = false;
                this.$refs.leaderApprovalRef.resetFields();
            }
        },
        auditLeader() {
            this.leaderApprovalForm.complaintId = this.caseDetailData.id;
            this.instance.post('/complaintInfo/auditLeader', this.leaderApprovalForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.leaderApprovalFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        assessmentReviewFn(submitFlag) {
            if (submitFlag) {
                this.$refs.assessmentReviewRef.validate((valid) => {
                    if (valid) {
                        this.auditCheck();
                    }
                });
            } else {
                this.assessmentReviewFlag = false;
                this.$refs.assessmentReviewRef.resetFields();
            }
        },
        auditCheck() {
            this.assessmentReviewForm.complaintId = this.complaintId;

            (this.assessmentReviewForm.distributeHandler = this.counselingUserObj[this.assessmentReviewForm.distributeHandlerUser]),
                this.instance.post('/complaintInfo/auditCheck', this.assessmentReviewForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('操作成功！');
                        this.assessmentReviewFlag = false;
                    } else {
                        this.$message.error(data.message);
                    }
                    this.updatePageInfo();
                });
        },
        agreeChange(typeNum) {
            if (typeNum == 1) {
                this.$refs.leaderApprovalRef.validate((valid) => {});
            } else {
                this.$refs.assessmentReviewRef.validate((valid) => {});
            }
        },
        confirmReassign(confirmFlag) {
            if (!confirmFlag) {
                this.reassignFlag = false;
            } else {
                this.$refs.reassignRef.validate((valid) => {
                    if (valid) {
                        this.distributeComplaint();
                    }
                });
            }
        },
        distributeComplaint() {
            this.reassignForm.complaintId = this.complaintId;
            this.reassignForm.distributeHandler = this.complaintCaseUserObj[this.reassignForm.distributeHandlerUser];
            this.instance.post('/complaintInfo/distributeComplaint', this.reassignForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('转派成功！');
                    this.reassignFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        getEditDetailForm(type) {
            if (type == 1) {
                this.$refs.caseInfoRef.editCaseDetail(null, null, this.$refs.caseSituationRef.editForm);
            } else {
                this.$refs.caseSituationRef.editCaseDetail(null, null, this.$refs.caseInfoRef.editForm);
            }
        },
        agreeChange1(type) {
            this.$refs.assessmentReviewRef.resetFields();
            this.assessmentReviewForm.distributeHandlerUser = '';
            this.assessmentReviewForm.remark = '';
        },
        saveEditInfo(saveFlag) {
            if (saveFlag) {
                this.$refs.caseInfoRef.editCaseDetail();
            } else {
                this.editInfoFlag = saveFlag;
                this.updatePageInfo();
            }
        }
    },
    watch: {
        iframeScrollTop: {
            handler(val) {
                this.activeBtns = val > 55;
            },
            immediate: true
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
@import '../../assets/css/caseCard.css';
</style>
<style lang="less" scoped>
.page_title {
    margin-bottom: 10px;
}
.page_content {
    ::-webkit-scrollbar {
        width: 0px;
        height: 10px;
        // background-color: red;
    }
    .cards_left {
        position: relative;
        .btn_groups {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 5;
        }
        .activeBtns {
            position: fixed;
            top: 100px;
            right: 22%;
            z-index: 5;
        }
    }
}
// 弹窗
.dialog_content {
    .el-row {
        margin-bottom: 30px;
        .title_col {
            text-align: right;
        }
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
}
.process_popup {
    .el-form-item__content {
        .el-select {
            width: 100%;
        }
    }
}

.textareaNumPosition {
    /deep/ .el-input__count,
    .el-input__count,
    /deep/ span {
        background: none !important;
        bottom: -25px !important;
        right: 3px !important;
    }
}
</style>